import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _ from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  Checkbox,
  message,
  notification,
  Typography,
  Tooltip,
} from "antd";
import { history } from "../../../../history";
import {
  getCustomList,
  deleteCustomList,
} from "../../../../redux/actions/managerActions/profile/actions";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";

import { changeStateValue_L1 } from "../../../../functions/commonFunctions/generalFunctions";
import {
  Patent_DefaultSelectedFilters,
  Patent_DefaultSearchFilters,
} from "../../../../constants/managerConstants/patentConstants";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import DownloadReportModal from "../../report/DownloadReportModal";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import CreateTask from "../../to-do-task/CreateTask";
import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { CP } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_CP_NOTES,
  PATCH_CP_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import { changeStateValue_L0 } from "../../../../functions/commonFunctions/generalFunctions";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import FilterSearchBasic from "../../../commonComponents/FilterComponents/FilterSearchBasic";

const { Text } = Typography;
const { Search } = Input;

class CustomPatent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      custom_patent_id: "",
      applicant_id: "",
      inventor_id: "",
      add_patent_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      searchFilters: Patent_DefaultSearchFilters,
      selectedFilters: this.props.lastAppliedFilters,
      pageNumber: this.props.pageNumber,
      changeSwitch: false,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.customPatent === null) {
        this.props.getCustomList({
          operation_type: "patent",
          object_type: "custom",
          filterFlag: false,
          filters: Patent_DefaultSelectedFilters,
          pageNumber: 1,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleCustomPatentProfile(data) {
    if (data) {
      sessionStorage.setItem("custom_patent_id", data.id);
      sessionStorage.setItem(
        "custom_patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/docketed-patent/custom-patent-profile/" + data.id,
        "_blank",
      );
      this.setState({
        custom_patent_id: data.id,
      });
    }
  }

  handleApplicantProfile(data) {
    if (data) {
      sessionStorage.setItem("applicant_id", data.id);
      window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
      this.setState({
        applicant_id: data.id,
      });
    }
  }

  handleInventorProfile(data) {
    if (data) {
      sessionStorage.setItem("inventor_id", data.id);
      window.open("/manager/patent/inventor-profile/" + data.id, "blank");
      this.setState({
        inventor_id: data.id,
      });
    }
  }

  downloadReport() {
    if (this.state.add_patent_id.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  async deleteMarks() {
    if (this.state.add_patent_id.length > 0) {
      await this.props.deleteCustomList({
        operation_type: "patent",
        object_type: "custom",
        selected_marks: this.state.add_patent_id,
      });
      notification[
        this.props.deleteCustomPatentStatus === SUCCESS
          ? "success"
          : this.props.deleteCustomPatentStatus === ERROR
            ? "error"
            : "smile"
      ]({
        message: "Delete Custom Patent",
        description:
          this.props.deleteCustomPatentStatus === SUCCESS
            ? this.props.deleteCustomPatent.message
            : this.props.deleteCustomPatentStatus === ERROR
              ? "Unfortunately, an error occured while Custom Patent Deleting. Please, try again later."
              : "",
      });
      this.props.getCustomList({
        operation_type: "patent",
        object_type: "custom",
        filterFlag: this.props.lastAppliedFilters.filterFlag,
        filters: this.props.lastAppliedFilters,
        pageNumber: this.props.pageNumber,
      });
      this.setState({
        add_patent_id: [],
        selectedRowKeys: [],
        selectAll: false,
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  applyFilter() {
    const {
      applicant,
      inventor,
      applicationType,
      fieldOfInvention,
      applicationStatus,
      legalStatus,
      classificationIPC,
      filingCountry,
      containsTerm,
    } = this.state.selectedFilters;

    if (
      applicant.length > 0 ||
      inventor.length > 0 ||
      applicationType.length > 0 ||
      fieldOfInvention.length > 0 ||
      applicationStatus.length > 0 ||
      legalStatus.length > 0 ||
      classificationIPC.length > 0 ||
      filingCountry.length > 0 ||
      containsTerm.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });
      this.props.getCustomList({
        operation_type: "patent",
        object_type: "custom",
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  resetFilter() {
    const {
      applicant,
      inventor,
      applicationType,
      fieldOfInvention,
      applicationStatus,
      legalStatus,
      classificationIPC,
      filingCountry,
      containsTerm,
    } = this.props.lastAppliedFilters;

    if (
      applicant.length > 0 ||
      inventor.length > 0 ||
      applicationType.length > 0 ||
      fieldOfInvention.length > 0 ||
      applicationStatus.length > 0 ||
      legalStatus.length > 0 ||
      classificationIPC.length > 0 ||
      filingCountry.length > 0 ||
      containsTerm.length > 0
    ) {
      this.setState({
        searchFilters: Patent_DefaultSearchFilters,
        selectedFilters: {
          ...this.state.selectedFilters,
          ...Patent_DefaultSelectedFilters,
          filterFlag: false,
        },
        pageNumber: 1,
      });
      this.props.getCustomList({
        operation_type: "patent",
        object_type: "custom",
        filterFlag: false,
        filters: Patent_DefaultSelectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  handleNextpage = (pageNumber) => {
    this.props.getCustomList({
      operation_type: "patent",
      object_type: "custom",
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Custom Patent Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: ["CUSTOM_PATENT"],
        report_tag: {
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          status: checkValue(data.application_status)
            ? data.application_status
            : "",
          report_name: "",
        },
        objectType: "custom_patent",
        applicationNumber: data.application_number,
      },
    });
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { searchFilters, selectedFilters } = this.state;

    const totalPatentColumns = [
      {
        title: "PATENT",
        dataIndex: "application_number",
        className: "reply-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleCustomPatentProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.title_of_invention)
                    ? record.title_of_invention.toLowerCase()
                    : "N.A."}
                </span>
              </p>
            </div>
          );
        },
      },

      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "reply-column-2",
        render: (applicant) => {
          return (
            <div>
              {applicant.length === 0 && <p>NA</p>}
              {applicant.map((applicantData, applicantKey) => (
                <p key={applicantKey} className="text-camelCase">
                  {checkValue(applicantData.name)
                    ? applicantData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "INVENTOR",
        dataIndex: "inventor",
        className: "reply-column-3",
        render: (inventor) => {
          return (
            <div>
              {inventor.length === 0 && <p>NA</p>}
              {inventor.map((inventorData, inventorKey) => (
                <p key={inventorKey} className="text-camelCase">
                  {checkValue(inventorData.name)
                    ? inventorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "TYPE",
        dataIndex: "application_type",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "FIELD OF INVENTION",
        dataIndex: "field_of_invention",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text?.name) ? text?.name?.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "STATUS",
        dataIndex: "application_status",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "FILING COUNTRY",
        dataIndex: "filing_country",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.name.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "DATE OF APPLICATION",
        dataIndex: "date_of_filing",
        className: "patent-column",
        render: (text) => (
          <p>{checkValue(text) ? text.toLowerCase() : "N.A."}</p>
        ),
      },

      {
        title: "ACTION",
        className: "reply-column-8",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalPatentRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_patent_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_patent_id]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },

      onSelectAll: (selected, selectedRows) => {
        let data = this.props.customPatent && this.props.customPatent.patents;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      // onSelect: (record, selected, selectedRows) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_patent_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let applicantMenu = noFilterData;
    let inventorMenu = noFilterData;
    let typeMenu = noFilterData;
    let fieldMenu = noFilterData;
    let statusMenu = noFilterData;
    let legalMenu = noFilterData;
    let filingCountryMenu = noFilterData;

    if (this.props.customPatentStatus === SUCCESS) {
      const {
        applicant,
        inventor,
        application_type,
        field_of_invention,
        application_status,
        legal_status,
        filing_country,
      } = this.props.customPatent.filters;

      if (applicant !== null && applicant.length > 0) {
        applicantMenu = (
          <FilterSearchBasic
            data={applicant}
            searchFilters={searchFilters}
            search_key={"applicantSearch"}
            filter_key={"applicant"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }

      if (inventor !== null && inventor.length > 0) {
        inventorMenu = (
          <FilterSearchBasic
            data={inventor}
            searchFilters={searchFilters}
            search_key={"inventorSearch"}
            filter_key={"inventor"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }

      if (application_type !== null && application_type.length > 0) {
        typeMenu = (
          <FilterSearchBasic
            data={application_type}
            searchFilters={searchFilters}
            search_key={"applicationTypeSearch"}
            filter_key={"applicationType"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (field_of_invention !== null && field_of_invention.length > 0) {
        fieldMenu = (
          <FilterSearchBasic
            data={field_of_invention}
            searchFilters={searchFilters}
            search_key={"fieldOfInventionSearch"}
            filter_key={"fieldOfInvention"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }

      if (application_status !== null && application_status.length > 0) {
        statusMenu = (
          <FilterSearchBasic
            data={application_status}
            searchFilters={searchFilters}
            search_key={"applicationStatusSearch"}
            filter_key={"applicationStatus"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (legal_status !== null && legal_status.length > 0) {
        legalMenu = (
          <FilterSearchBasic
            data={legal_status}
            searchFilters={searchFilters}
            search_key={"legalStatusSearch"}
            filter_key={"legalStatus"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (filing_country !== null && filing_country.length > 0) {
        filingCountryMenu = (
          <FilterSearchBasic
            data={filing_country}
            searchFilters={searchFilters}
            search_key={"filingCountrySearch"}
            filter_key={"filingCountry"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }
    }

    return (
      <div>
        {/* Back */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={12}
            className="alignC"
            style={{ fontSize: "14px!important" }}
          >
            <Text strong style={{ margin: "0px", fontSize: "16px" }}>
              TOTAL CUSTOM PATENT :{" "}
              {checkValue(this.props.customPatent)
                ? this.props.customPatent.count
                : 0}{" "}
            </Text>
          </Col>
        </Row>
        {/* Back */}

        {/* Custom Patents filter */}
        <Row>
          <Col span={18} className="alignC m-top-10">
            <Menu
              selectedKeys={[
                selectedFilters?.applicant.length > 0 ? "applicantMenu" : "",
                selectedFilters?.inventor.length > 0 ? "inventorMenu" : "",
                selectedFilters?.applicationType.length > 0 ? "typeMenu" : "",
                selectedFilters?.fieldOfInvention.length > 0 ? "fieldMenu" : "",
                selectedFilters?.applicationStatus.length > 0
                  ? "statusMenu"
                  : "",
                selectedFilters?.legalStatus.length > 0 ? "legalMenu" : "",
                selectedFilters?.filingCountry.length > 0
                  ? "filingCountryMenu"
                  : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="applicantMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => applicantMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "applicantType")
                  }
                  open={this.state.open === "applicantType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Applicant </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="inventorMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => inventorMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "inventorType")
                  }
                  open={this.state.open === "inventorType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Inventor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="typeMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => typeMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "typeMenu")
                  }
                  open={this.state.open === "typeMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Type </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="fieldMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => fieldMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "fieldMenu")
                  }
                  open={this.state.open === "fieldMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Field of Invention </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="statusMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => statusMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statusMenu")
                  }
                  open={this.state.open === "statusMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="legalMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => legalMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "legalMenu")
                  }
                  open={this.state.open === "legalMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Legal Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="filingCountryMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => filingCountryMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "filingCountryMenu")
                  }
                  open={this.state.open === "filingCountryMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Filing Country </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Col>
          <Col
            span={6}
            className="alignR m-top-10"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Text style={{ padding: "0px 5px", wordBreak: "keep-all" }}>
              {this.state.selectAll
                ? this.props.customPatent && this.props.customPatent.count
                : _.uniq(this.state.add_patent_id).length}{" "}
              Mark(s)
            </Text>
            <Button
              type="primary"
              style={{ margin: "0px 5px" }}
              onClick={() => this.downloadReport()}
            >
              Report
            </Button>
            <Button
              danger
              style={{
                backgroundColor: "#FDE5E7",
                color: "red",
                display: "flex",
                alignItems: "center",
              }}
              loading={this.props.deleteCustomPatentStatus === LOADING}
              onClick={() => this.deleteMarks()}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            offset={7}
            span={9}
            className="alignC"
            style={{ borderBottom: "1px solid #E9E9E9" }}
          >
            <Menu
              selectedKeys={[]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item
                key="contentTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L1(
                      "selectedFilters",
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={this.state?.selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                  onKeyDown={(event) => {
                    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                      event.stopPropagation(); // Prevent arrow keys from propagating
                    }
                  }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* Custom Patents filter */}

        {/* custom Patents table */}
        <Row>
          <Col span={24}>
            <div className="m-top-20 custom-patent">
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={totalPatentRowSelection}
                columns={totalPatentColumns}
                dataSource={
                  this.props.customPatent && this.props.customPatent.patents
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: this.props.pageNumber,
                  total:
                    this.props.customPatent && this.props.customPatent.count,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(100vh - 300px)" }}
                loading={
                  this.props.customPatentStatus === LOADING ? true : false
                }
              />
            </div>
          </Col>
        </Row>
        {/* custom Patents table */}

        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"patent"}
            customPatent={"custom_patent"}
            add_patent_id={this.state.add_patent_id}
            selectAll={this.state.selectAll}
            filters={this.props.lastAppliedFilters}
          />
        )}

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"patent"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_CP_NOTES, PATCH_CP_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={CP}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
        />
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  getCustomList,
  deleteCustomList,
  logOut,
};

function mapStateToProps(state) {
  const {
    customPatent,
    customPatentStatus,
    customPatentAppliedFilters,
    customPatentPageNumber,
    deleteCustomPatent,
    deleteCustomPatentStatus,
  } = state.managerState.profileState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    customPatent,
    customPatentStatus,
    lastAppliedFilters: customPatentAppliedFilters,
    pageNumber: customPatentPageNumber,
    deleteCustomPatent,
    deleteCustomPatentStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPatent);
