import React, { Component } from "react";
import { connect } from "react-redux";
import { FixedSizeList } from "react-window";
import deepFreeze from "deep-freeze";
import _, { debounce } from "lodash";

import {
  CarryOutTwoTone,
  CheckCircleTwoTone,
  DownOutlined,
  LoadingOutlined,
  MessageTwoTone,
  MinusCircleOutlined,
  TagTwoTone,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  Typography,
  Checkbox,
  Tooltip,
  Switch,
  notification,
  Modal,
  Layout,
  Spin,
  Badge,
} from "antd";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { getDashboard } from "../../../../redux/actions/managerActions/profile/actions";
import { getReplyToCorrespondence } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
  TM_DefaultFilterConfig,
} from "../../../../constants/managerConstants/trademarkConstants";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";

//--------MODALS--------
import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import ReplyFiledModal from "../ReplyFiled";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { TM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";
import { reportCols } from "./utils";
import FilterSearchPaginate from "../../../commonComponents/FilterComponents/FilterSearchPaginate";
import FilterSearchBasic from "../../../commonComponents/FilterComponents/FilterSearchBasic";

const { Text, Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

class ReplyToCorrespondence extends Component {
  constructor(props) {
    let loc = window.location.pathname.split("/");
    const root = loc.includes("your-trademark") ? "YOUR_TM" : "OPPOSED_TM";

    let mmType = "trademark";
    if (root === "OPPOSED_TM") mmType = "opposed";
    super(props);
    this.state = {
      root: root,
      visible: false,
      management_trademark_id: "",
      addTaskVisible: false,
      changeSwitch: false,
      tag_color: "",
      range: null,

      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      checkAll: false,
      noTagToggle: false,

      pseudo_add_trademark_id: [],
      pseudo_selectedRowKeys: [],
      pseudo_selectAll: false,
      pseudo_checkAll: false,

      selectedFilters: {
        ip_india: checkValue(this.props.replyToCorrespondenceAppliedFilters)
          ? this.props.replyToCorrespondenceAppliedFilters
          : TM_DefaultSelectedFilters,
        pseudo: checkValue(this.props.pseudoReplyToCorrespondenceAppliedFilters)
          ? this.props.pseudoReplyToCorrespondenceAppliedFilters
          : TM_DefaultSelectedFilters,
      },

      searchFilters: {
        ip_india: TM_DefaultSearchFilters,
        pseudo: TM_DefaultSearchFilters,
      },
      filterDocDOD: "doc_name",
      filterRemainingDate: "remaining_days",
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
      filterKeys: [
        "PROPRIETOR",
        "CLASSES",
        "TRADEMARK_TYPE",
        "TAG",
        "TRADEMARK_STATUS",
        "TRADEMARK_STATES",
        "TRADEMARK_OPPONENT",
        "ALERTS",
      ],
      mmType: mmType,
      filterConfig: TM_DefaultFilterConfig,
    };
    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.setDate = this.setDate.bind(this);
    this.handleSearchDebounced = this.handleSearchDebounced.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.tmDashboard === null) {
        this.props.getDashboard("trademark", "trademark");
      }

      this.props.getReplyToCorrespondence({
        mmType: this.state.mmType,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });

      this.state.filterKeys.map((key) =>
        this.props.getReplyToCorrespondence({
          mmType: this.state.mmType,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: key,
          page: 1,
        }),
      );
      if (this.props.userSubscriptions?.manager?.includes("PSEUDO_TRADEMARK")) {
        this.props.getReplyToCorrespondence({
          mmType: this.state.mmType,
          pseudo: true,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });

        this.state.filterKeys.map((key) =>
          this.props.getReplyToCorrespondence({
            mmType: this.state.mmType,
            pseudo: true,
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
            filters_required_flag: true,
            required_filter: key,
            page: 1,
          }),
        );
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }
  //BackClickHandler
  backClickHandler() {
    window.history.back();
  }

  //Rerendering component after reply filed complitation/ calling api again after filed reply
  reRender() {
    this.props.getReplyToCorrespondence({
      mmType: this.state.mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
    this.state.filterKeys.map((key) =>
      this.props.getReplyToCorrespondence({
        mmType: this.state.mmType,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: key,
        page: 1,
      }),
    );
    if (this.props.userSubscriptions?.manager?.includes("PSEUDO_TRADEMARK")) {
      this.props.getReplyToCorrespondence({
        mmType: this.state.mmType,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        pseudo: true,
      });
      this.state.filterKeys.map((key) =>
        this.props.getReplyToCorrespondence({
          mmType: this.state.mmType,
          pseudo: true,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: key,
          page: 1,
        }),
      );
    }
    this.setState({
      selectAll: false,
      pseudo_selectAll: false,
      pseudo_add_trademark_id: [],
      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
    });
  }

  handlePdfClick(data) {
    let url = "";
    if (data?.file) {
      url = data?.file;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  handlePdfClick1(data) {
    let url = "";
    if (data?.url.includes("http://")) {
      url = data?.url;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  replyFiled() {
    if (this.state.selectAll) {
      this.setState({ add_trademark_id: [] });
    } else if (this.state.pseudo_selectAll) {
      this.setState({ pseudo_add_trademark_id: [] });
    } else if (
      this.state.add_trademark_id?.length <= 0 &&
      this.state.pseudo_add_trademark_id?.length <= 0
    ) {
      message.error("no mark selected");
      return;
    }

    this.setState({
      replyFiledVisible: true,
      pending_reply: false,

      pseudo: this.state.changeSwitch,
    });
  }

  downloadReport() {
    const { changeSwitch, add_trademark_id, pseudo_add_trademark_id } =
      this.state;

    if (
      changeSwitch &&
      (pseudo_add_trademark_id?.length > 0 || this.state.pseudo_selectAll)
    ) {
      this.setState({
        visible: true,
      });
    } else if (add_trademark_id?.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  reasonShow(data) {
    Modal.info({
      title: "Reason for Notice",
      width: "50%",
      content: (
        <div>
          <p>{data}</p>
        </div>
      ),
      onOk() {},
    });
  }

  //   *********************************NO Tags ********************************//

  handleNoTag = (e) => {
    this.setState({ noTagToggle: !this.state.noTagToggle });
  };

  setDate(val) {
    this.setState({ range: val });
  }

  resetFilter() {
    const { changeSwitch } = this.state;
    if (
      changeSwitch
        ? this.props.pseudoTMState?.pseudoTotalTrademarksAppliedFilters
        : this.props.totalTMState?.totalTrademarksAppliedFilters
    ) {
      const {
        trademarkClass,
        trademarkStatus,
        trademarkType,
        trademarkStates,
        trademarkProprietors,
        trademarkTags,
        trademarkOpponent,
        // evidenceFilter,
        date_of_application,
        containsTerm,
        trademarkAlerts,
        trademarkNOtags,
      } = changeSwitch
        ? this.props.pseudoTMState?.pseudoTotalTrademarksAppliedFilters
        : this.props.totalTMState?.totalTrademarksAppliedFilters;

      if (
        trademarkClass?.length > 0 ||
        trademarkStatus?.length > 0 ||
        trademarkType?.length > 0 ||
        trademarkStates?.length > 0 ||
        trademarkProprietors?.length > 0 ||
        trademarkTags?.length > 0 ||
        trademarkOpponent?.length > 0 ||
        // evidenceFilter === true ||
        date_of_application?.length > 0 ||
        containsTerm?.length > 0 ||
        trademarkAlerts?.length > 0 ||
        trademarkNOtags?.length > 0
      ) {
        this.props.getReplyToCorrespondence({
          mmType: this.state.mmType,
          pseudo: changeSwitch,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.setState({
          range: null,
          noTagToggle: false,
          selectedFilters: {
            ...this.state.selectedFilters,
            [changeSwitch ? "pseudo" : "ip_india"]: {
              ...this.state.selectedFilters[
                changeSwitch ? "pseudo" : "ip_india"
              ],
              ...TM_DefaultSelectedFilters,
              filterFlag: false,
              pageNumber: 1,
            },
          },
        });
      } else {
        message.error("No Filter Applied");
      }
    }
  }

  applyFilter() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkStates,
      trademarkProprietors,
      trademarkTags,
      trademarkOpponent,
      // evidenceFilter,
      date_of_application,
      containsTerm,
      trademarkAlerts,
      trademarkNOtags,
    } = selectedFilters;

    if (
      trademarkClass?.length > 0 ||
      trademarkStatus?.length > 0 ||
      trademarkType?.length > 0 ||
      trademarkStates?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkOpponent?.length > 0 ||
      // evidenceFilter === true ||
      date_of_application?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkAlerts?.length > 0 ||
      trademarkNOtags?.length > 0
    ) {
      this.props.getReplyToCorrespondence({
        mmType: this.state.mmType,
        pseudo: changeSwitch,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
      });
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [changeSwitch ? "pseudo" : "ip_india"]: {
            ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
            ...selectedFilters,
            filterFlag: true,
            pageNumber: 1,
          },
        },
      });
    } else {
      message.error("please select filter!");
    }
  }

  handleNextpage = (pageNumber) => {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    this.props.getReplyToCorrespondence({
      mmType: this.state.mmType,
      pseudo: changeSwitch,
      filterFlag: selectedFilters?.filterFlag,
      filters: selectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [changeSwitch ? "pseudo" : "ip_india"]: {
          ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
          pageNumber: pageNumber,
        },
      },
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal) ? data.journal.number : "",
          journal_date: checkValue(data.journal)
            ? data.journal.date_of_publication
            : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent_name)
            ? data.opponent_name
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  fetchFilters = (key, filter_contains_term, page) => {
    this.props.getReplyToCorrespondence({
      mmType: this.state.mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: page,
      filters_required_flag: true,
      required_filter: key,
      filter_contains_term: filter_contains_term,
      page: page,
      pseudo: this.state.changeSwitch,
    });
  };

  handleSearchDebounced = debounce(function (
    required_filter,
    filter_contains_term,
  ) {
    this.fetchFilters(required_filter, filter_contains_term, 1);
  }, 500);

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });

    this.handleSearchDebounced(required_filter, filter_contains_term);
  };

  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    const filterObj = this.state.changeSwitch
      ? this.props.pseudoTMState?.filters
      : this.props.totalTMState?.filters;
    const isFilterLoading = this.state.changeSwitch
      ? this.props.pseudoTMState?.filters?.filterStatus
      : this.props.totalTMState?.filters?.filterStatus;

    if (filterObj.notesFilterStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;

      let count = 0;

      this.state.filterKeys.forEach((key) => {
        if (required_filter === key) {
          count = filterObj[`${key}FilterDataCount`];
        }
      });

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages && isFilterLoading !== "LOADING") {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });
        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  render() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const searchFilters = changeSwitch
      ? this.state.searchFilters.pseudo
      : this.state.searchFilters.ip_india;
    const currentPageNumber = changeSwitch
      ? this.props.pseudoTMState.pseudoTotalTrademarksPageNumber
      : this.props.totalTMState.totalTrademarksPageNumber;

    let switchValue;
    if (changeSwitch) {
      switchValue = "pseudo";
    } else {
      switchValue = "ip_india";
    }

    let isFilterLoading = changeSwitch
      ? this.props.pseudoTMState?.filters[`PROPRIETORFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`CLASSESFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_TYPEFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TAGFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_STATUSFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_STATESFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_OPPONENTFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`JOURNALFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`ALERTSFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_OFFICEFilterStatus`] ===
          LOADING
      : this.props.totalTMState?.filters[`PROPRIETORFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`CLASSESFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_TYPEFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TAGFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_STATUSFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_STATESFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_OPPONENTFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`JOURNALFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`ALERTSFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_OFFICEFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters?.filterStatus === LOADING;

    const classes = changeSwitch
      ? this.props.pseudoTMState?.filters?.CLASSESFilterData
      : this.props.totalTMState?.filters?.CLASSESFilterData;
    const status = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_STATUSFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_STATUSFilterData;
    const type = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_TYPEFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_TYPEFilterData;
    const states = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_STATESFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_STATESFilterData;
    const proprietors = changeSwitch
      ? this.props.pseudoTMState?.filters?.PROPRIETORFilterData
      : this.props.totalTMState?.filters?.PROPRIETORFilterData;
    const tags = changeSwitch
      ? this.props.pseudoTMState?.filters?.TAGFilterData
      : this.props.totalTMState?.filters?.TAGFilterData;
    const opponent = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_OPPONENTFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_OPPONENTFilterData;
    const alerts = changeSwitch
      ? this.props.pseudoTMState?.filters?.ALERTSFilterData
      : this.props.totalTMState?.filters?.ALERTSFilterData;
    const report_columns = reportCols;
    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let statusMenu = noFilterData;
    let tagsMenu = noFilterData;
    let opponentMenu = noFilterData;
    let alertMenu = noFilterData;
    let reportMenu = noFilterData;

    const selectedFiltersObj = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;

    alertMenu = (
      <FilterSearchBasic
        data={alerts}
        searchFilters={searchFilters}
        search_key={"alertSearch"}
        filter_key={"trademarkAlerts"}
        selectedFilters={selectedFilters}
        switchValue={switchValue}
        onSelect={this.changeStateValue_L2}
      />
    );

    if (classes !== null && classes.length > 0) {
      classesMenu = (
        <FilterSearchBasic
          data={classes}
          searchFilters={searchFilters}
          search_key={"classSearch"}
          filter_key={"trademarkClass"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }
    statusMenu = (
      <FilterSearchBasic
        data={status}
        searchFilters={searchFilters}
        search_key={"statusSearch"}
        filter_key={"trademarkStatus"}
        selectedFilters={selectedFilters}
        switchValue={switchValue}
        onSelect={this.changeStateValue_L2}
        type={"object"}
      />
    );

    tagsMenu = (
      <FilterSearchPaginate
        datasource={tags}
        filter_key={"trademarkTags"}
        req_filter={"TAG"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          let required_filter = "TAG";
          this.onFilterPaginate(
            required_filter,
            this.state?.filterConfig[`${required_filter}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${required_filter}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Tags"}
        searchValue={this.state?.filterConfig["TAG"]?.filter_contains_term}
        switchValue={switchValue}
        type={"noTag"}
        handleNoTag={this.handleNoTag}
        noTagToggle={this.state.noTagToggle}
      />
    );

    opponentMenu = (
      <FilterSearchPaginate
        datasource={opponent}
        filter_key={"trademarkOpponent"}
        req_filter={"TRADEMARK_OPPONENT"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "TRADEMARK_OPPONENT",
            this.state?.filterConfig[`${"TRADEMARK_OPPONENT"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"TRADEMARK_OPPONENT"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Opponent"}
        searchValue={
          this.state?.filterConfig["TRADEMARK_OPPONENT"]?.filter_contains_term
        }
        switchValue={switchValue}
      />
    );

    if (report_columns !== null && report_columns?.length > 0) {
      // let report_cols = reportCols;
      let filteredReport = report_columns.filter((eachReport) =>
        checkValue(eachReport)
          ? eachReport
              .toLowerCase()
              .includes(searchFilters.reportSearch?.toLowerCase())
          : "",
      );
      reportMenu = (
        <FilterSearchBasic
          data={report_columns}
          searchFilters={searchFilters}
          search_key={"reportSearch"}
          filter_key={"reportColumn"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    const getColumnFilter = (dataIndex) => ({
      filtered:
        (dataIndex === "tags" &&
          (selectedFilters?.trademarkTags?.length > 0 ||
            selectedFilters?.trademarkNOtags?.length > 0)) ||
        (dataIndex === "alert" &&
          selectedFilters?.trademarkAlerts?.length > 0) ||
        (dataIndex === "opponent" &&
          selectedFilters?.trademarkOpponent?.length > 0) ||
        (dataIndex === "status" &&
          selectedFilters?.trademarkStatus?.length > 0) ||
        (dataIndex === "associated_class" &&
          selectedFilters?.trademarkClass?.length > 0) ||
        (dataIndex === "doa" &&
          selectedFilters?.date_of_application?.length > 0),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          {dataIndex === "tags" && tagsMenu}
          {dataIndex === "alert" && alertMenu}
          {dataIndex === "opponent" && opponentMenu}
          {dataIndex === "status" && statusMenu}
          {dataIndex === "associated_class" && classesMenu}
          {dataIndex === "doa" && (
            <DateRangePicker
              type={"Application Date"}
              startValue={selectedFilters.date_of_application_startValue}
              endValue={selectedFilters.date_of_application_endValue}
              setDate={(val) => this.setDate(val)}
              range={this.state.range}
              handleDateString={(startValue, endValue, dateString) =>
                this.setState({
                  selectedFilters: {
                    ...this.state.selectedFilters,
                    [switchValue]: {
                      ...this.state.selectedFilters[switchValue],
                      date_of_application_startValue: startValue,
                      date_of_application_endValue: endValue,
                      date_of_application:
                        startValue !== null || endValue !== null
                          ? dateString
                          : null,
                    },
                  },
                })
              }
            />
          )}
        </div>
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    });

    const totalReplyToCorrespondenceColumns = [
      {
        title: "TRADEMARK",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "CLASS",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.associated_class)
                  ? record.associated_class.join(", ")
                  : "N.A."}
              </p>
            </div>
          );
        },
        ...getColumnFilter("associated_class"),
      },
      {
        title: "STATUS ",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.status)
                  ? record.status.toLowerCase()
                  : "N.A."}
              </p>
            </div>
          );
        },
        ...getColumnFilter("status"),
      },
      {
        title: "DOA",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.application_date)
                  ? record.application_date
                  : "N.A."}
              </p>
            </div>
          );
        },
        ...getColumnFilter("doa"),
      },

      {
        title: "OPPONENT NAME | OPPONENT NUMBER",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <span className="text-camelCase">
                {checkValue(record.opponent_name)
                  ? record.opponent_name.toLowerCase()
                  : "N.A."}
              </span>
              {record.opponent_number && (
                <p>
                  (
                  {checkValue(record.opponent_number)
                    ? record.opponent_number
                    : "N.A."}
                  )
                </p>
              )}
            </div>
          );
        },
        ...getColumnFilter("opponent"),
      },

      {
        title: "DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.documents &&
                record.documents?.length > 0 &&
                record.documents?.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc?.description}
                    </span>
                    <p>({checkValue(doc?.date) ? doc?.date : "N.A."})</p>
                    <div>
                      <span>{doc?.remaining_days} Days</span>
                      <span className={doc?.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {this.state.root === "YOUR_TM" &&
                record?.status === "Formalities Chk Fail" &&
                record.correspondence_notices &&
                record.correspondence_notices?.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc?.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc?.date_of_correspondence)
                          ? doc?.date_of_correspondence
                          : "N.A."}
                        )
                      </p>
                      <div>
                        <span>{doc?.remaining_days} Days</span>
                        <span
                          className={doc?.alert ? "visible" : "not-visible"}
                        >
                          <Tooltip
                            title="Deadline to file reply for this mark has passed"
                            className="toolTip-custom"
                          >
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                color: "#e1001b",
                                paddingLeft: "5px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </p>
                ))}
              {this.state.root === "OPPOSED_TM" &&
                record.correspondence_notices &&
                record.correspondence_notices?.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc?.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc?.date_of_correspondence)
                          ? doc?.date_of_correspondence
                          : "N.A."}
                        )
                      </p>
                      <div>
                        <span>{doc?.remaining_days} Days</span>
                        <span
                          className={doc?.alert ? "visible" : "not-visible"}
                        >
                          <Tooltip
                            title="Deadline to file reply for this mark has passed"
                            className="toolTip-custom"
                          >
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                color: "#e1001b",
                                paddingLeft: "5px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </p>
                ))}
              {record.examination_report_documents &&
                record.examination_report_documents?.length > 0 &&
                record.examination_report_documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={
                        checkValue(doc?.url)
                          ? () => this.handlePdfClick1(doc)
                          : ""
                      }
                      style={
                        checkValue(doc?.url)
                          ? { cursor: "pointer", color: "#4285f4" }
                          : {}
                      }
                    >
                      EXAMINATION REPORT
                    </span>
                    <p>({checkValue(doc?.date) ? doc?.date : "N.A."})</p>
                    <div>
                      <span>{doc?.remaining_days} Days</span>
                      <span className={doc?.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.documents?.length === 0 &&
                record.correspondence_notices?.length === 0 &&
                record.examination_report_documents?.length === 0 && (
                  <p className="h_80px vertical_scroll">N.A.</p>
                )}
            </div>
          );
        },
      },

      {
        title: "DISPATCH DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.correspondence_notices &&
                record.correspondence_notices?.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc?.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc?.date_of_dispatch)
                          ? doc?.date_of_dispatch
                          : "N.A."}
                        )
                      </p>
                    </div>
                    <div>
                      <span>{doc?.remaining_days_wrt_dod} Days</span>
                      <span
                        className={
                          doc?.alert_wrt_dod ? "visible" : "not-visible"
                        }
                      >
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices?.length === 0 && (
                <p className="h_80px vertical_scroll">N.A.</p>
              )}
            </div>
          );
        },
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        className: "reply-to-correspondence-column-1",
        render: (tags) => {
          return (
            <div>
              {tags?.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
        ...getColumnFilter("tags"),
      },

      {
        title: "ALERTS",
        dataIndex: "alert",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
        ...getColumnFilter("alert"),
      },

      {
        title: "ACTION",
        className: "reply-to-correspondence-column-1",
        render: (action) => {
          return (
            <div
              className="iconTable"
              style={{
                minHeight: "16rem",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              {/* <p className="application-text alignC" onClick={() => this.replyFiled(action)}>Reply Filed</p> */}
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalReplyToCorrespondenceRowSelection = {
      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.totalTMState &&
          this.props.totalTMState.totalTrademarks &&
          this.props.totalTMState.totalTrademarks.ip_india &&
          this.props.totalTMState.totalTrademarks.ip_india.trademarks;

        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: [],
                  selectAll: !this.state.selectAll,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    const pseudoReplyToCorrespondenceRowSelection = {
      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.pseudoTMState &&
          this.props.pseudoTMState.pseudoTotalTrademarks &&
          this.props.pseudoTMState.pseudoTotalTrademarks.pseudo &&
          this.props.pseudoTMState.pseudoTotalTrademarks.pseudo.trademarks;

        this.setState({
          pseudo_selectAll: selected,
          pseudo_selectedRows: selectedRows,
          pseudo_selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            pseudo_selectedRowKeys: newSelected.map((x) => x.id),
            pseudo_add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            pseudo_selectedRowKeys: newSelected.map((x) => x.id),
            pseudo_add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },
      hideDefaultSelections: true,
      selections: this.state.pseudo_selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  pseudo_selectedRowKeys: changeableRowKeys,
                  pseudo_selectAll: false,
                  pseudo_add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.pseudo_selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.pseudo_selectAll,
      }),
    };

    let stateMenu = noFilterData;
    let typeMenu = noFilterData;
    let newTypeMenu = noFilterData;
    let proprietorsMenu = noFilterData;

    if (type !== null && type?.length > 0) {
      typeMenu = (
        <FilterSearchBasic
          data={type}
          searchFilters={searchFilters}
          search_key={"typeSearch"}
          filter_key={"trademarkType"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    if (states !== null && states?.length > 0) {
      stateMenu = (
        <FilterSearchBasic
          data={states}
          searchFilters={searchFilters}
          search_key={"stateSearch"}
          filter_key={"trademarkStates"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    proprietorsMenu = (
      <FilterSearchPaginate
        datasource={proprietors}
        filter_key={"trademarkProprietors"}
        req_filter={"PROPRIETOR"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "PROPRIETOR",
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Proprietors"}
        searchValue={
          this.state?.filterConfig["PROPRIETOR"]?.filter_contains_term
        }
        switchValue={switchValue}
      />
    );

    // }

    const title = (
      <Row>
        <Col xs={{ span: 12 }} lg={{ span: 20 }}>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {(this.state.root === "YOUR_TM" &&
              (changeSwitch
                ? "Your Pending Replies (Custom)"
                : "Your Pending Replies")) ||
              (this.state.root === "OPPOSED_TM" &&
                (changeSwitch
                  ? "3rd Party Pending Replies (Custom)"
                  : "3rd Party Pending Replies"))}
          </Title>
          <Text style={{ fontSize: "16px" }} type="secondary">
            {changeSwitch
              ? `Total number of trademarks: ${
                  (this.props.pseudoTMState.pseudoTotalTrademarksStatus ===
                  LOADING
                    ? 0
                    : this.props.pseudoTMState.pseudoTotalTrademarks &&
                      this.props.pseudoTMState.pseudoTotalTrademarks.ip_india &&
                      this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                        .count) || 0
                }`
              : `Total number of trademarks: ${
                  (this.props.totalTMState.totalTrademarksStatus === LOADING
                    ? 0
                    : this.props.totalTMState.totalTrademarks &&
                      this.props.totalTMState.totalTrademarks.ip_india &&
                      this.props.totalTMState.totalTrademarks.ip_india.count) ||
                  0
                }`}
          </Text>
        </Col>
        <Col
          span={4}
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes(
                  "PSEUDO_TRADEMARK",
                )
                ? "alignR m-top-10"
                : "display_none"
              : "display_none"
          }
        >
          <Text strong>IP INDIA</Text>
          <Switch
            className="change-manager-type"
            onChange={() => this.changeSwitch()}
            checked={changeSwitch}
          />
          <Text strong>CUSTOM</Text>
        </Col>
      </Row>
    );

    const handleOpenChange = (flag, key) => {
      if (flag) {
        this.setState({
          open: key,
        });
      } else {
        this.setState({
          open: null,
        });
      }
    };

    return (
      <div>
        {/* Back */}
        <Row>
          <Col span={16} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => this.backClickHandler()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col span={8} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.tmDashboard)
                ? this.props.tmDashboard.trademark.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>

        <Content style={{ margin: "8px 16px" }}>
          {title}
          {/* <Row>
            <Col style={{ fontSize: "14px!important" }}>{title}</Col>
          </Row> */}
          {/* Back */}

          {/* reply to correspondence filter */}
          <Row justify="space-between">
            <Col span={15}>
              <Menu
                selectedKeys={[
                  selectedFilters?.trademarkType?.length > 0 ? "typeMenu" : "",
                  selectedFilters?.trademarkStates?.length > 0
                    ? "stateMenu"
                    : "",
                  selectedFilters?.trademarkProprietors?.length > 0
                    ? "proprietorsMenu"
                    : "",
                ]}
                className="filter-list"
                mode="horizontal"
              >
                <Menu.Item
                  key="contentTerm"
                  className="filter-list-item apply-filter"
                >
                  <Input
                    placeholder="Application No/Name"
                    onChange={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "containsTerm",
                        event.target.value,
                      )
                    }
                    value={selectedFilters?.containsTerm}
                    allowClear
                    style={{ width: 200 }}
                    onKeyDown={(event) => {
                      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                        event.stopPropagation(); // Prevent arrow keys from propagating
                      }
                    }}
                  />
                </Menu.Item>
                <Menu.Item key="typeMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => typeMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "type")}
                    open={this.state.open === "type"}
                  >
                    <div className="ant-dropdown-link">
                      <span>Type </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="stateMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => stateMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "state")}
                    open={this.state.open === "state"}
                  >
                    <div className="ant-dropdown-link">
                      <span>State </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="proprietorsMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => proprietorsMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "prop")}
                    open={this.state.open === "prop"}
                  >
                    <div className="ant-dropdown-link">
                      <span>Proprietor </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item
                  key="applyFilter"
                  className="filter-list-item apply-filter"
                >
                  <Button type="primary" onClick={() => this.applyFilter()}>
                    <i className="fa fa-filter" aria-hidden="true" />
                    <Text style={{ color: "#fff", marginLeft: "5px" }}>
                      Apply Filter
                    </Text>
                  </Button>
                </Menu.Item>
                <Menu.Item
                  key="resetFilter"
                  className="filter-list-item apply-filter"
                >
                  <span
                    onClick={() => this.resetFilter()}
                    style={{ padding: "0px 5px" }}
                  >
                    RESET
                  </span>
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={9}>
              <Menu
                selectedKeys={[
                  selectedFilters.reportColumn?.length > 0 ? "reportMenu" : "",
                ]}
                className="filter-list"
                mode="horizontal"
                selectable={false}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Menu.Item
                  key="replyFiled"
                  className="filter-list-item report-button-menu"
                >
                  <Button
                    type="dashed"
                    // style={{ borderLeft: 'thick solid black' , borderRight: 'thick solid black'}}
                    onClick={() => this.replyFiled()}
                    style={{
                      padding: "0px 0px 0px 12px",
                    }}
                    disabled={
                      this.state?.selectAll || this.state?.pseudo_selectAll
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleTwoTone />

                      <span
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Reply Filed
                      </span>
                    </div>
                  </Button>
                </Menu.Item>
                <Menu.Item key="reportMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => reportMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "report")}
                    open={this.state.open === "report"}
                  >
                    <div
                      className="ant-dropdown-link"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        Report Column{" "}
                      </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item
                  key="reportDownload"
                  className="filter-list-item report-button-menu"
                >
                  <Tooltip title="Deselect All">
                    <Button
                      danger
                      style={{
                        padding: "0 8px",
                      }}
                      onClick={() => {
                        this.setState({
                          selectedRowKeys: [],
                          selectAll: false,
                          add_trademark_id: [],
                          selectedRows: [],
                          pseudo_selectedRowKeys: [],
                          pseudo_selectAll: false,
                          pseudo_add_trademark_id: [],
                        });
                      }}
                      disabled={
                        (changeSwitch
                          ? this.state.pseudo_selectAll
                            ? this.props.pseudoTotalTrademarks &&
                              this.props.pseudoTotalTrademarks.pseudo.count
                            : _.uniq(this.state.pseudo_add_trademark_id)
                                .length === 0
                          : this.state.selectAll
                          ? this.props.totalTMState.totalTrademarks &&
                            this.props.totalTMState.totalTrademarks.ip_india
                              .count
                          : _.uniq(this.state.add_trademark_id).length === 0) &&
                        !(changeSwitch
                          ? this.state.pseudo_selectAll
                          : this.state.selectAll)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MinusCircleOutlined
                          style={{ margin: 0, fontSize: 16 }}
                        />
                      </div>
                    </Button>
                  </Tooltip>
                  <Badge
                    count={
                      (changeSwitch
                        ? this.state.pseudo_selectAll && "All"
                        : this.state.selectAll && "All") ||
                      (changeSwitch
                        ? this.state.pseudo_selectAll
                          ? this.props.pseudoTotalTrademarks &&
                            this.props.pseudoTotalTrademarks.pseudo.count
                          : _.uniq(this.state.pseudo_add_trademark_id)?.length
                        : this.state.selectAll
                        ? this.props.totalTMState.totalTrademarks &&
                          this.props.totalTMState.totalTrademarks.ip_india.count
                        : _.uniq(this.state.add_trademark_id)?.length)
                    }
                    style={{ marginTop: "5px" }}
                  >
                    <Button
                      type="primary"
                      onClick={() => this.downloadReport()}
                      style={{ margin: "0px 10px 0px 10px" }}
                    >
                      Report
                    </Button>
                  </Badge>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
          {/* reply to correspondence filter */}

          {/* reply to correspondence */}
          <Row>
            <Col span={24}>
              <div className="m-top-20 pending-replies">
                <Table
                  bordered
                  rowKey={(record) => record.id}
                  rowSelection={
                    changeSwitch
                      ? pseudoReplyToCorrespondenceRowSelection
                      : totalReplyToCorrespondenceRowSelection
                  }
                  columns={totalReplyToCorrespondenceColumns}
                  dataSource={
                    changeSwitch
                      ? this.props.pseudoTMState &&
                        this.props.pseudoTMState.pseudoTotalTrademarks &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                          .trademarks
                      : this.props.totalTMState &&
                        this.props.totalTMState.totalTrademarks &&
                        this.props.totalTMState.totalTrademarks.ip_india &&
                        this.props.totalTMState.totalTrademarks.ip_india
                          .trademarks
                  }
                  pagination={{
                    pageSize: 100,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                    current: currentPageNumber,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} `,
                    total: changeSwitch
                      ? this.props.pseudoTMState &&
                        this.props.pseudoTMState.pseudoTotalTrademarks &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                          .count
                      : this.props.totalTMState &&
                        this.props.totalTMState.totalTrademarks &&
                        this.props.totalTMState.totalTrademarks.ip_india &&
                        this.props.totalTMState.totalTrademarks.ip_india.count,
                    onChange: this.handleNextpage,
                  }}
                  scroll={{ y: "calc(100vh - 320px)" }}
                  loading={
                    changeSwitch
                      ? this.props.pseudoTMState
                          ?.pseudoTotalTrademarksStatus === LOADING
                        ? true
                        : false
                      : this.props.totalTMState?.totalTrademarksStatus ===
                        LOADING
                      ? true
                      : false
                  }
                />
              </div>
            </Col>
          </Row>
          {/* reply to correspondence  */}
          {this.state.visible && (
            <DownloadReportModal
              visible={this.state.visible}
              onCloseModal={() => {
                this.setState({ visible: false });
              }}
              type={"trademark"}
              mm_type={
                this.state.mmType === "trademark" ? "trademark" : "opposed"
              }
              add_trademark_id={
                changeSwitch
                  ? this.state.pseudo_add_trademark_id
                  : this.state.add_trademark_id
              }
              selected_pending_replies={
                changeSwitch
                  ? this.state.pseudo_add_trademark_id
                  : this.state.add_trademark_id
              }
              all_pending_replies={
                changeSwitch
                  ? this.state.pseudo_selectAll
                  : this.state.selectAll
              }
              pending_reply_to_correspondence={true}
              changeSwitch={changeSwitch}
              comparative={
                checkValue(this.props.userSubscriptions)
                  ? this.props.userSubscriptions.manager.includes(
                      "PSEUDO_TRADEMARK",
                    )
                  : false
              }
              filters={
                changeSwitch
                  ? this.props.pseudoTMState.pseudoTotalTrademarksAppliedFilters
                  : this.props.totalTMState.totalTrademarksAppliedFilters
              }
              report_columns={
                changeSwitch
                  ? this.state.selectedFilters.pseudo.reportColumn
                  : this.state.selectedFilters.ip_india.reportColumn
              }
            />
          )}

          <ReplyFiledModal
            // visible={this.state.replyFiledVisible}
            // onCloseModal = {()=> this.changeStateValue_L0('replyFiledVisible', false)}
            // mm_type = {'reply_to_correspondance'}
            // pending_reply = {this.state.pending_reply}
            // application_number = {this.state.application_number}
            // applied_for = {this.state.applied_for}
            // pseudo = {this.state.pseudo}
            // mark_type={'Portfolio trademark'}
            // actionType={REPLY_FILED_TO_CORRESPONDENCE}
            //-----------------------------------------------------------------------
            visible={this.state.replyFiledVisible}
            onCloseModal={() => {
              this.changeStateValue_L0("replyFiledVisible", false);
            }}
            mm_type={"reply_to_correspondance"}
            pending_reply={this.state.pending_reply}
            application_number={
              changeSwitch
                ? this.state.pseudo_add_trademark_id
                : this.state.add_trademark_id
            }
            applied_for={this.state.applied_for}
            pseudo={this.state.changeSwitch}
            mark_type={
              this.state.mmType === "trademark"
                ? "Portfolio trademark"
                : "Third party Opposed trademark"
            }
            actionType={"REPLY_FILED_TO_CORRESPONDENCE_NEW"}
            selectAll={
              this.state.changeSwitch
                ? this.state.pseudo_selectAll
                : this.state.selectAll
            }
            reRender={() => this.reRender()}
            filters={
              changeSwitch
                ? this.props.pseudoTMState.pseudoTotalTrademarksAppliedFilters
                : this.props.totalTMState.totalTrademarksAppliedFilters
            }
            count={
              changeSwitch
                ? _.uniq(this.state.pseudo_add_trademark_id)?.length
                : _.uniq(this.state.add_trademark_id)?.length
            }
          />

          <CreateTask
            visible={this.state.addTaskVisible}
            onCloseModal={() =>
              this.changeStateValue_L0("addTaskVisible", false)
            }
            type={"trademark"}
          />

          <ManagerNotes
            visible={this.state.managerNotes}
            onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
            actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
            email={
              checkValue(this.props.userDetails)
                ? this.props.userDetails.email
                : ""
            }
            note_for={TM}
            application_number={this.state.application_number}
            type={"ADD"}
          />

          <SendEmailNew
            visible={this.state.modalsVisibility.sendEmail}
            onCloseModal={() =>
              this.handleEmail("modalsVisibility", "sendEmail", false, {})
            }
            sendFrom={
              this.props.userDetails &&
              this.props.userDetails.communication_email
            }
            sendCC={this.props.userTeam && this.props.userTeam.manager}
            mailContent={this.state.modalsVisibility.mailContent}
            product={this.state.modalsVisibility.product}
            subscription={this.state.modalsVisibility.subscription}
            report_tag={this.state.modalsVisibility.report_tag}
            objectType={this.state.modalsVisibility.objectType}
            mm_type={
              this.state.mmType === "trademark"
                ? "Portfolio trademark"
                : "Third party Opposed trademark"
            }
            applicationNumber={this.state.modalsVisibility.applicationNumber}
            markId={this.state.modalsVisibility.markId}
            doc_link={true}
            sourceId={this.state.modalsVisibility.sourceId}
            source={`TRADEMARK`}
            sub_section="DASHBOARD"
            managerEmail={true}
          />
        </Content>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getReplyToCorrespondence,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { tmDashboard, tmDashboardStatus } = state.managerState.profileState;
  const {
    pseudo,
    totalTrademarks,
    totalTrademarksStatus,
    totalTrademarksAppliedFilters,
    totalTrademarksPageNumber,

    pseudoTotalTrademarks,
    pseudoTotalTrademarksStatus,
    pseudoTotalTrademarksAppliedFilters,
    pseudoTotalTrademarksPageNumber,

    totalTMState,
    pseudoTMState,
  } = state.managerState.trademarkDashboardState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    tmDashboard,
    tmDashboardStatus,
    pseudo,

    totalTrademarks,
    totalTrademarksStatus,
    totalTrademarksAppliedFilters,
    totalTrademarksPageNumber,

    pseudoTotalTrademarks,
    pseudoTotalTrademarksStatus,
    pseudoTotalTrademarksAppliedFilters,
    pseudoTotalTrademarksPageNumber,

    //..
    totalTMState,
    pseudoTMState,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplyToCorrespondence);
