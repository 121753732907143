import { Button, Form, Modal, notification } from "antd";
import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientNotesData,
  updateClientNotesData,
  updateSingleClientNotesData,
} from "../../../../../redux/actions/commonActions/masterActions/clientNotes";

const ClientNotesAddComponent = ({ open, setOpen, filters, type }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.clientNotes,
  );

  const singleClientNotes = useSelector(
    (state) => state.masters.clientMaster.singleClientNotes,
  );

  const { pageNumber } = metadata;

  const [editorValue, setEditorValue] = useState(
    type === "add" ? "<p></p>" : singleClientNotes?.data?.note.toString(),
  );

  const onEditorChange = (evt) => {
    setEditorValue(evt.editor.getData());
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    dispatch(updateSingleClientNotesData({}));
  };

  useEffect(() => {
    if (singleClientNotes && type !== "add") {
      let contentText = singleClientNotes?.data?.note.toString();
      setEditorValue(contentText ?? "<p></p>");
      form.setFieldsValue({ note: contentText });
    }
  }, [form, singleClientNotes, type]);

  const handleSubmit = () => {
    if (editorValue.trim() === "" || editorValue === "<p></p>") {
      form.setFields([
        {
          name: "note",
          errors: ["Please enter something!"],
        },
      ]);
      return;
    }

    let body = {
      note: editorValue,
      client_id: filters?.client_id,
    };

    if (type === "add") {
      dispatch(
        addClientNotesData(body, pageNumber, {
          onSuccess: (successResponse) => {
            onClose();
            notification["success"]({
              message: successResponse?.message ?? "Notes Added Successfully!",
            });
          },
        }),
      );
    } else {
      body = {
        ...body,
        id: singleClientNotes?.data?.id,
      };
      dispatch(
        updateClientNotesData(body, pageNumber, {
          onSuccess: (successResponse) => {
            onClose();
            notification["success"]({
              message:
                successResponse?.message ?? "Notes Updated Successfully!",
            });
          },
        }),
      );
    }
  };

  return (
    <Modal
      title={type === "add" ? "Add Note" : "Edit Note"}
      centered
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {type === "add" ? "Add" : "Update"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "65vh", overflowY: "auto" }}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ margin: "15px 10px" }}
        onFinish={handleSubmit}
        initialValues={editorValue}
      >
        <div className="text-editor">
          <Form.Item
            name="note"
            rules={[
              {
                required: true,
                message: "Please enter something!",
              },
            ]}
          >
            <CKEditor
              initData={editorValue}
              name="editor"
              onChange={onEditorChange}
              editorUrl={"https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"}
              config={{
                allowedContent: true,
                extraAllowedContent: "div(*)",
                extraPlugins: ["editorplaceholder"],
                editorplaceholder: "Start typing here...",
                toolbarGroups: [
                  { name: "basicstyles", groups: ["basicstyles"] },
                  { name: "paragraph", groups: ["list", "align"] },
                  { name: "links" },
                  { name: "insert", groups: ["table"] },
                  { name: "styles" },
                  { name: "links" },
                ],
                removeButtons:
                  "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,PasteFromWord",
                autoGrow_onStartup: true,
                height: "40vh",
                removePlugins: "flash,iframe",
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ClientNotesAddComponent;
