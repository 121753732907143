//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import Axios from "axios";

import {
  CloudUploadOutlined,
  DeleteOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  notification,
  Typography,
  Select,
  Tooltip,
  Tabs,
  Badge,
  Checkbox,
  Popover,
  Space,
} from "antd";

//--------MANGER: ACTIONS--------
// import { postmanageClientEmail, postDownloadFile } from '../../redux/actions/managerActions/trademarkDashboard/actions';
import {
  getClientEmails,
  markDownload,
  logOut,
  setStoreData,
  deleteClientEmail,
  createCEReport,
} from "../../redux/actions/commonActions/actions";
// import { getClientEmails } from "../../redux/actions/managerActions/commonAction/action";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
  MANAGER,
} from "../../constants/commonConstants/generalConstants";

//--------MANAGER CONSTANTS--------
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
} from "../../constants/managerConstants/trademarkConstants";

import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../functions/commonFunctions/checkValueFunctions";

//--------IP-SUITE COMMON COMPONENTS--------
import AddClientEmails from "./Modals/AddClientEmail";
import EditClientEmail from "./Modals/EditClientEmail";

//--------RT_CONFIG--------
import { MGR_RT_URL } from "../../configs/rootConfigs";

import FilterSearchPaginate from "../commonComponents/CustomComponents/FilterSearchPaginate";
import { TM_DefaultFilterConfig } from "../../constants/managerConstants/trademarkConstants";

import ClientEmailDetails from "./Modals/ClientEmailDetails";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Text, Title } = Typography;
const { Option } = Select;

const TRADEMARK = "TRADEMARK";
const PATENT = "PATENT";
const JOURNAL_WATCH = "JOURNAL_WATCH";
const TM_PROTECT = "TM_PROTECT";

const CUSTOM_TRADEMARK = "CUSTOM_TRADEMARK";
const INTERNATIONAL_TRADEMARK = "INTERNATIONAL_TRADEMARK";
const CUSTOM_PATENT = "CUSTOM_PATENT";
const INTERNATIONAL_PATENT = "INTERNATIONAL_PATENT";
const NOFILE = "NO_FILE_SELECTED";

const PFMARK = "Portfolio trademark";
const TPMARK = "Third party Opposed trademark";
const STMARK = "Status tracker";

class ManageEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      management_mark_id: "",
      // editingKey: "",
      add_trademark_id: [],
      add_application_no: [],
      selectAll: false,
      searchFilters: TM_DefaultSearchFilters,
      selectedFilters: TM_DefaultSelectedFilters,
      pageNumber: 1,
      drawersVisibility: {
        manageClientEmail: false,
        manageTags: false,
        downloadPortfolio: false,
      },
      modalsVisibility: {
        addClientEmail: false,
        edit: false,
        detail: false,
      },
      // value: this.props.selectedSubscription,
      subscription: this.props.selectedSubscription,
      markType: this.props.mmType,

      selectedRows: [],
      selectedEmails: [],
      selectedRowKeys: [],

      filterConfig: TM_DefaultFilterConfig,
      no_email: false,
      file_name: "",
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  fetchFilters = (key, filter_contains_term, page) => {
    this.props.getClientEmails({
      no_email: this.state.no_email,
      mmType: this.props.mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: page,
      filters_required_flag: true,
      required_filter: key,
      filter_contains_term: filter_contains_term,
      page: page,
      subscription: this.props.selectedSubscription,
    });
  };

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });
    this.fetchFilters(required_filter, filter_contains_term, 1);
  };

  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    const filterObj = this.props?.filters;
    const isFilterLoading = this.props.filters?.filterStatus;

    if (filterObj.notesFilterStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;

      let count = 0;

      count = filterObj[`${required_filter}FilterDataCount`];

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages && isFilterLoading !== "LOADING") {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });
        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (
        this.props?.userSubscriptions?.manager?.length > 0 &&
        this.props?.userSubscriptions?.manager?.includes("TRADEMARK")
      ) {
        this.props.setStoreData({
          activeTab: "TRADEMARK",
          selectedSubscription: "TRADEMARK",
        });
        this.props.getClientEmails({
          no_email: this.state.no_email,
          subscription: "TRADEMARK",
          mmType: "Portfolio trademark",
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });

        if (
          this.props.filters?.PROPRIETORFilterData &&
          this.props.filters?.PROPRIETORFilterData.length <= 0
        ) {
          this.props.getClientEmails({
            no_email: this.state.no_email,
            mmType: PFMARK,
            subscription: TRADEMARK,
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
            filters_required_flag: true,
            required_filter: "PROPRIETOR",
            page: 1,
          });
        }
      } else if (
        this.props?.userSubscriptions?.manager?.length > 0 &&
        this.props?.userSubscriptions?.manager?.includes("PATENT")
      ) {
        this.props.setStoreData({
          activeTab: "PATENT",
          selectedSubscription: "PATENT",
        });
        this.props.getClientEmails({
          no_email: this.state.no_email,
          subscription: "PATENT",
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });

        if (
          this.props.filters?.INVENTORFilterData &&
          this.props.filters?.INVENTORFilterData.length <= 0
        ) {
          this.props.getClientEmails({
            no_email: this.state.no_email,
            mmType: this.props.mmType,
            subscription: PATENT,
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
            filters_required_flag: true,
            required_filter: "INVENTOR",
            page: 1,
          });
        }
        if (
          this.props.filters?.APPLICANTFilterData &&
          this.props.filters?.APPLICANTFilterData.length <= 0
        ) {
          this.props.getClientEmails({
            no_email: this.state.no_email,
            mmType: this.props.mmType,
            subscription: PATENT,
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
            filters_required_flag: true,
            required_filter: "APPLICANT",
            page: 1,
          });
        }
      } else {
        if (
          this.props.userSubscriptions.watch.length > 0 &&
          this.props?.userSubscriptions?.watch?.includes("Journal Watch")
        ) {
          this.props.setStoreData({
            activeTab: "JOURNAL_WATCH",
            selectedSubscription: "JOURNAL_WATCH",
          });

          this.props.getClientEmails({
            no_email: this.state.no_email,
            subscription: "JOURNAL_WATCH",
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
          });

          if (
            this.props.filters?.PROPRIETORFilterData &&
            this.props.filters?.PROPRIETORFilterData.length <= 0
          ) {
            this.props.getClientEmails({
              no_email: this.state.no_email,
              subscription: JOURNAL_WATCH,
              filterFlag: false,
              filters: TM_DefaultSelectedFilters,
              pageNumber: 1,
              filters_required_flag: true,
              required_filter: "PROPRIETOR",
              page: 1,
            });
          }
        } else if (
          this.props.userSubscriptions.watch.length > 0 &&
          this.props?.userSubscriptions?.watch?.includes("TM Protect")
        ) {
          this.props.setStoreData({
            activeTab: "JOURNAL_WATCH",
            selectedSubscription: "TM_PROTECT",
          });

          this.props.getClientEmails({
            no_email: this.state.no_email,
            subscription: "TM_PROTECT",
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
          });
        }
      }
    } else {
      this.props.logOut();
    }
  }

  resetComponent = () => {
    this.setState({
      pageNumber: 1,
      selectedRowKeys: [],
      selectAll: false,
      selectedRows: [],
      no_email: false,
    });
  };

  getFiltersSubscriptionWise = (subscription) => {
    if (
      [
        TRADEMARK,
        CUSTOM_TRADEMARK,
        INTERNATIONAL_TRADEMARK,
        JOURNAL_WATCH,
      ].includes(subscription)
    ) {
      this.props.getClientEmails({
        no_email: this.state.no_email,
        mmType: this.props.mmType,
        subscription: subscription,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
    } else if (
      [PATENT, CUSTOM_PATENT, INTERNATIONAL_PATENT].includes(subscription)
    ) {
      this.props.getClientEmails({
        no_email: this.state.no_email,
        subscription: subscription,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "INVENTOR",
        page: 1,
      });
      this.props.getClientEmails({
        no_email: this.state.no_email,
        subscription: subscription,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "APPLICANT",
        page: 1,
      });
    }

    this.resetComponent();
  };

  handleSubscriptionChange = (subscription) => {
    //data
    this.props.getClientEmails({
      no_email: this.state.no_email,
      mmType: this.props.mmType,
      subscription: subscription,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });

    //filters
    this.getFiltersSubscriptionWise(subscription);

    this.setState({
      subscription: subscription,
      selectedFilters: TM_DefaultSelectedFilters,
      searchFilters: TM_DefaultSearchFilters,
      add_application_no: [],
      add_trademark_id: [],
      selectedRowKeys: [],
      selectAll: false,
    });
    this.props.setStoreData({
      selectedSubscription: subscription,
    });

    this.resetComponent();
  };

  handleMMtypeChange = (mmType) => {
    //data
    this.props.getClientEmails({
      no_email: this.state.no_email,
      mmType: mmType,
      subscription: this.props.selectedSubscription,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });

    this.props.getClientEmails({
      no_email: this.state.no_email,
      subscription: this.props.selectedSubscription,
      mmType: mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
      filters_required_flag: true,
      required_filter: "PROPRIETOR",
      page: 1,
    });

    this.setState({
      mmType: mmType,
      selectedFilters: TM_DefaultSelectedFilters,
      searchFilters: TM_DefaultSearchFilters,
      add_application_no: [],
      add_trademark_id: [],
      selectedRowKeys: [],
      selectAll: false,
    });
    this.props.setStoreData({
      mmType,
    });

    this.resetComponent();
  };

  handleTabChange = (activeKey) => {
    this.setState({
      selectedFilters: TM_DefaultSelectedFilters,
    });
    if (activeKey === TRADEMARK) {
      this.props.setStoreData({
        activeTab: activeKey,
        selectedSubscription: TRADEMARK,
        mmType: this.props.mmType,
      });
    } else {
      this.props.setStoreData({
        activeTab: activeKey,
        selectedSubscription: activeKey,
      });
    }
    if ([TRADEMARK, JOURNAL_WATCH].includes(activeKey)) {
      this.props.getClientEmails({
        no_email: this.state.no_email,
        mmType: PFMARK,
        subscription: activeKey,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
    }

    this.props.getClientEmails({
      no_email: this.state.no_email,
      mmType: this.props.mmType,
      subscription: activeKey,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
    if (activeKey === PATENT) {
      if (
        this.props.filters?.INVENTORFilterData &&
        this.props.filters?.INVENTORFilterData.length <= 0
      ) {
        this.props.getClientEmails({
          no_email: this.state.no_email,
          mmType: this.props.mmType,
          subscription: PATENT,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "INVENTOR",
          page: 1,
        });
      }
      if (
        this.props.filters?.APPLICANTFilterData &&
        this.props.filters?.APPLICANTFilterData.length <= 0
      ) {
        this.props.getClientEmails({
          no_email: this.state.no_email,
          mmType: this.props.mmType,
          subscription: PATENT,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "APPLICANT",
          page: 1,
        });
      }
    }

    this.resetComponent();
  };

  editClientEmail(data) {
    this.setState({
      drawersVisibility: {
        ...this.state.drawersVisibility,
        manageClientEmail: data,
      },
    });
  }

  applyFilter() {
    const {
      trademarkProprietors,
      containsTerm,
      patentApplicants,
      patentInventors,
    } = this.state.selectedFilters;
    if (
      trademarkProprietors.length > 0 ||
      containsTerm.length > 0 ||
      patentApplicants.length > 0 ||
      patentInventors.length > 0 ||
      this.state.no_email
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });

      this.props.getClientEmails({
        no_email: this.state.no_email,
        subscription: this.props.selectedSubscription,
        mmType: this.props.mmType,
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  resetFilter() {
    this.setState({
      searchFilters: TM_DefaultSearchFilters,
      selectedFilters: {
        ...this.state.selectedFilters,
        ...TM_DefaultSelectedFilters,
        filterFlag: false,
      },
      pageNumber: 1,
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      add_trademark_id: [],
      add_application_no: [],
      no_email: false,
    });
    this.props.getClientEmails({
      no_email: false,
      subscription: this.props.selectedSubscription,
      mmType: this.props.mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
  }

  resetState = () => {
    const {
      trademarkProprietors,
      containsTerm,
      patentApplicants,
      patentInventors,
    } = this.state.selectedFilters;
    if (
      trademarkProprietors.length > 0 ||
      containsTerm.length > 0 ||
      patentApplicants.length > 0 ||
      patentInventors.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: this.state.pageNumber,
      });

      this.props.getClientEmails({
        no_email: this.state.no_email,
        subscription: this.props.selectedSubscription,
        mmType: this.props.mmType,
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: this.state.pageNumber,
      });
    } else {
      this.props.getClientEmails({
        no_email: this.state.no_email,
        subscription: this.props.selectedSubscription,
        mmType: this.props.mmType,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: this.state.pageNumber,
      });
    }

    this.setState({
      pageNumber: this.state.pageNumber,
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      add_trademark_id: [],
      add_application_no: [],
      file_name: "",
    });
  };

  handleNextpage = async (pageNumber) => {
    let subscription = this.props.selectedSubscription;

    await this.props.getClientEmails({
      no_email: this.state.no_email,
      subscription: subscription,
      mmType: this.props.mmType,
      filterFlag: this.state.selectedFilters.filterFlag,
      filters: this.state.selectedFilters,
      pageNumber: pageNumber,
    });

    if (this.state.selectAll) {
      this.setState({
        pageNumber: pageNumber,
        selectedRowKeys: this.props[subscription].clientEmail?.data?.map(
          (x) => x?.id
        ),
      });
    } else {
      this.setState({
        pageNumber: pageNumber,
      });
    }
  };

  submitFile = (event, application_number) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file[0]);
    formData.append("application_number", application_number);
    Axios.post(`${MGR_RT_URL}/test-upload/`, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // handle your response;
      })
      .catch((error) => {
        // handle your error
      });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  handleDelete = async (email, deletionType) => {
    await this.props.deleteClientEmail({
      email,
      deletionType,
      selectedEmails: this.state.selectedRowKeys,
      subscription: this.props.selectedSubscription,
      mmType: this.props.mmType,
      filters: this.state.selectedFilters,
      allFlag: this.state.selectAll,
    });

    notification[
      this.props.deleteClientEmailStatus === SUCCESS
        ? "success"
        : this.props.deleteClientEmailStatus === ERROR
        ? "error"
        : "smile"
    ]({
      message: "Email Deleted",
      // description:
      //   this.props.deleteClientEmailStatus === SUCCESS
      //     ? "Successfully Deleted Email"
      //     : this.props.deleteClientEmailStatus === ERROR
      //     ? "Unable to Delete Email. Please try again later!"
      //     : "",
    });

    this.resetState();
  };
  createReport = async (email, deletionType) => {
    await this.props.createCEReport({
      email,
      selectedEmails: this.state.selectedRowKeys,
      file_name: this.state.file_name ? this.state.file_name : "",
      subscription: this.props.selectedSubscription,
      mmType: this.props.mmType,
      filters: this.state.selectedFilters,
      allFlag: this.state.selectAll,
      no_email: this.state.no_email,
    });

    notification[
      this.props.createCEReportStatus === SUCCESS
        ? "success"
        : this.props.createCEReportStatus === ERROR
        ? "error"
        : "smile"
    ]({
      message: "Report Generation Initiated",
      description:
        this.props.createCEReportStatus === SUCCESS
          ? "You can visit the Reports -> Client Email Reports section to access the report."
          : this.props.createCEReportStatus === ERROR
          ? "Unable to Create Report. Please try again later!"
          : "",
    });

    this.resetState();
  };

  handleEdit = (eachMail) => {
    this.props.setStoreData({
      editEmailData: {
        client_email_id: eachMail?.id,
        client_email: eachMail?.email,
        person_name: eachMail?.person_name,
        company_name: eachMail?.company_name,
      },
    });

    this.changeStateValue_L1("modalsVisibility", "edit", true);
  };

  deleteEmailEntityData = async (email, deletionType) => {
    await this.props.deleteClientEmail({
      email,
      deletionType: "CLIENT_EMAIL_ENTITY",
      selectedEmails: this.state.selectedRowKeys,
      subscription: this.props.selectedSubscription,
      mmType: this.props.mmType,
      filters: this.state.selectedFilters,
      allFlag: this.state.selectAll,
    });

    notification[
      this.props.deleteClientEmailStatus === SUCCESS
        ? "success"
        : this.props.deleteClientEmailStatus === ERROR
        ? "error"
        : "smile"
    ]({
      message: "Deleted Emails",
      description:
        this.props.deleteClientEmailStatus === SUCCESS
          ? "Successfully Deleted Emails"
          : this.props.deleteClientEmailStatus === ERROR
          ? "Unable to Deleted Email. Please try again later!"
          : "",
    });

    // this.resetFilter();
    this.resetState();
  };

  getEmailEntity = () => {
    let subscription = this.props.selectedSubscription;

    return this.props[subscription]?.clientEmail?.data?.length > 0
      ? this.props[subscription]?.clientEmail?.data?.filter(
          (x) => x?.id === this.props?.selectedEmailDetail?.id
        )[0]
      : {
          application_number: "",
          applied_for: "",
          associated_image: "",
          client_emails: "",
          id: "",
          proprietors: "",
        };
  };

  handleNoEmailCheckbox = (e) => {
    this.setState({
      no_email: e.target.checked,
    });

    this.props.getClientEmails({
      subscription: this.props.selectedSubscription,
      mmType: this.props.mmType,
      filterFlag: true,
      filters: this.state.selectedFilters,
      pageNumber: 1,
      no_email: e.target.checked,
    });
  };

  render() {
    const getColumnFilter = (dataIndex) => ({
      filtered:
        (dataIndex === "inventors" &&
          this.state.selectedFilters?.patentInventors?.length > 0) ||
        (dataIndex === "applicants" &&
          this.state.selectedFilters?.patentApplicants?.length > 0) ||
        (dataIndex === "proprietors" &&
          this.state.selectedFilters?.trademarkProprietors?.length > 0),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "applicants" && applicantsMenu}
          {dataIndex === "inventors" && inventorsMenu}
          {dataIndex === "proprietors" && proprietorsMenu}
        </div>
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    });

    let manageClientEmailColumns;

    if (
      ["TRADEMARK", "CUSTOM_TRADEMARK", "INTERNATIONAL_TRADEMARK"].includes(
        this.props.selectedSubscription
      )
    ) {
      manageClientEmailColumns = [
        {
          title: "APPLICATION NO.",
          dataIndex: "application_number",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "IMAGE",
          dataIndex: "associated_image",
          className: "manage-trademark-column",
          render: (text) => {
            return (
              <>
                {checkValue(text) && text !== "N.A." ? (
                  <img className="trademark-image" src={text} alt="" />
                ) : (
                  <p>N.A.</p>
                )}
              </>
            );
          },
        },

        {
          title: "APPLIED FOR",
          dataIndex: "applied_for",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietors",
          className: "manage-trademark-column",
          ...getColumnFilter("proprietors"),
          render: (proprietors) => {
            return (
              <div>
                {proprietors?.length
                  ? proprietors?.map((proprietorData) => (
                      <p key={proprietorData.id}>
                        {checkValue(proprietorData.name)
                          ? proprietorData.name
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },
        {
          title: "EMAILS",
          width: "20%",
          filtered: this.state.no_email,
          className: "manage-trademark-column",
          filterDropdown: (obj) => (
            <div style={{ padding: 8 }}>
              <Checkbox
                checked={this.state.no_email}
                onChange={this.handleNoEmailCheckbox}
              >
                No Emails
              </Checkbox>
            </div>
          ),
          render: (record) => {
            return (
              <div
                className="vertical_scroll"
                style={{
                  maxHeight: 180,
                  overflow: "auto",
                  paddingLeft: "12px",
                }}
              >
                {record.client_emails &&
                  record.client_emails.length > 0 &&
                  record.client_emails.map((eachMail, key) => (
                    <div>
                      <div>
                        <span>{`${key + 1}. `}</span>
                        <span>
                          <strong>
                            {checkValue(eachMail.email)
                              ? eachMail.email
                              : "N.A."}
                          </strong>
                        </span>
                      </div>
                    </div>
                  ))}
                {record.client_emails.length === 0 && (
                  <div>
                    <p>N.A.</p>
                  </div>
                )}
              </div>
            );
          },
        },

        {
          title: "ACTIONS",
          dataIndex: "application_number",
          className: "manage-trademark-column-action",
          key: "actions",
          width: "10%",
          render: (application_number, record) => {
            return (
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  margin: "8px",
                }}
                onClick={() => {
                  this.props.setStoreData({
                    selectedEmailDetail: record,
                  });
                  this.changeStateValue_L1("modalsVisibility", "detail", true);
                }}
              >
                <i className="fa fa-sliders" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>Manage</Text>
              </Button>
            );
          },
        },
      ];
    } else if (
      ["PATENT", "CUSTOM_PATENT", "INTERNATIONAL_PATENT"].includes(
        this.props.selectedSubscription
      )
    ) {
      manageClientEmailColumns = [
        {
          title: "APPLICATION NO.",
          dataIndex: "application_number",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "TITLE",
          dataIndex: "title_of_invention",
          className: "manage-trademark-column",
          render: (text) => (
            <div
              className="vertical_scroll"
              style={{ maxHeight: 180, overflow: "auto", paddingLeft: "12px" }}
            >
              <p>{checkValue(text) ? text : "N.A."}</p>
            </div>
          ),
        },

        {
          title: "APPLICANT",
          dataIndex: "applicant",
          className: "manage-trademark-column",
          ...getColumnFilter("applicants"),
          render: (applicant) => {
            return (
              <div
                className="vertical_scroll"
                style={{ maxHeight: 180, overflow: "auto" }}
              >
                {applicant?.length > 0
                  ? applicant?.map((proprietorData) => (
                      <p key={proprietorData.id}>
                        {checkValue(proprietorData.name)
                          ? proprietorData.name
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },
        {
          title: "INVENTOR",
          dataIndex: "inventor",
          className: "manage-trademark-column",
          ...getColumnFilter("inventors"),
          render: (inventor) => {
            return (
              <div
                className="vertical_scroll"
                style={{ maxHeight: 180, overflow: "auto" }}
              >
                {inventor?.length
                  ? inventor?.map((proprietorData) => (
                      <p key={proprietorData.id}>
                        {checkValue(proprietorData.name)
                          ? proprietorData.name
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },
        {
          title: "EMAILS",
          width: "20%",
          className: "manage-trademark-column",
          filtered: this.state.no_email,
          filterDropdown: (obj) => (
            <div style={{ padding: 8 }}>
              <Checkbox
                checked={this.state.no_email}
                onChange={this.handleNoEmailCheckbox}
              >
                No Emails
              </Checkbox>
            </div>
          ),
          render: (record) => {
            return (
              <div
                className="vertical_scroll"
                style={{
                  maxHeight: 180,
                  overflow: "auto",
                  paddingLeft: "12px",
                }}
              >
                {record.client_emails &&
                  record.client_emails.length > 0 &&
                  record.client_emails.map((eachMail, key) => (
                    <div>
                      <div>
                        <span>{`${key + 1}. `}</span>
                        <span>
                          <strong>
                            {checkValue(eachMail.email)
                              ? eachMail.email
                              : "N.A."}
                          </strong>
                        </span>
                      </div>
                    </div>
                  ))}
                {record.client_emails.length === 0 && (
                  <div>
                    <p>N.A.</p>
                  </div>
                )}
              </div>
            );
          },
        },

        {
          title: "ACTIONS",
          dataIndex: "application_number",
          className: "manage-trademark-column-action",
          key: "actions",
          width: "10%",
          render: (application_number, record) => {
            return (
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  margin: "8px",
                }}
                onClick={() => {
                  this.props.setStoreData({
                    selectedEmailDetail: record,
                  });
                  this.changeStateValue_L1("modalsVisibility", "detail", true);
                }}
              >
                <i className="fa fa-sliders" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>Manage</Text>
              </Button>
            );
          },
        },
      ];
    } else if (this.props.selectedSubscription === JOURNAL_WATCH) {
      manageClientEmailColumns = [
        {
          title: "APPLICATION NO.",
          dataIndex: "application_number",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },
        {
          title: "IMAGE",
          dataIndex: "associated_image",
          className: "manage-trademark-column",
          render: (text) => {
            return (
              <>
                {checkValue(text) && text !== "N.A." ? (
                  <img className="trademark-image" src={text} alt="" />
                ) : (
                  <p>N.A.</p>
                )}
              </>
            );
          },
        },

        {
          title: "APPLIED FOR",
          dataIndex: "applied_for",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietors",
          className: "manage-trademark-column",
          ...getColumnFilter("proprietors"),
          render: (proprietors) => {
            return (
              <div>
                {proprietors?.length
                  ? proprietors?.map((proprietorData) => (
                      <p key={proprietorData.id}>
                        {checkValue(proprietorData.name)
                          ? proprietorData.name
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },
        {
          title: "EMAILS",
          width: "20%",
          className: "manage-trademark-column",
          filtered: this.state.no_email,
          filterDropdown: (obj) => (
            <div style={{ padding: 8 }}>
              <Checkbox
                checked={this.state.no_email}
                onChange={this.handleNoEmailCheckbox}
              >
                No Emails
              </Checkbox>
            </div>
          ),
          render: (record) => {
            return (
              <div
                className="vertical_scroll"
                style={{
                  maxHeight: 180,
                  overflow: "auto",
                  paddingLeft: "12px",
                }}
              >
                {record.client_emails &&
                  record.client_emails.length > 0 &&
                  record.client_emails.map((eachMail, key) => (
                    <div>
                      <div>
                        <span>{`${key + 1}. `}</span>
                        <span>
                          <strong>
                            {checkValue(eachMail.email)
                              ? eachMail.email
                              : "N.A."}
                          </strong>
                        </span>
                      </div>
                    </div>
                  ))}
                {record.client_emails.length === 0 && (
                  <div>
                    <p>N.A.</p>
                  </div>
                )}
              </div>
            );
          },
        },

        {
          title: "ACTIONS",
          dataIndex: "application_number",
          className: "manage-trademark-column-action",
          key: "actions",
          width: "10%",
          render: (application_number, record) => {
            return (
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  margin: "8px",
                }}
                onClick={() => {
                  this.props.setStoreData({
                    selectedEmailDetail: record,
                  });
                  this.changeStateValue_L1("modalsVisibility", "detail", true);
                }}
              >
                <i className="fa fa-sliders" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>Manage</Text>
              </Button>
            );
          },
        },
      ];
    } else if (this.props.selectedSubscription === TM_PROTECT) {
      manageClientEmailColumns = [
        {
          title: "Term",
          dataIndex: "term",
          className: "manage-trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },
        {
          title: "EMAILS",
          width: "20%",
          className: "manage-trademark-column",
          filtered: this.state.no_email,
          filterDropdown: (obj) => (
            <div style={{ padding: 8 }}>
              <Checkbox
                checked={this.state.no_email}
                onChange={this.handleNoEmailCheckbox}
              >
                No Emails
              </Checkbox>
            </div>
          ),
          render: (record) => {
            return (
              <div
                className="vertical_scroll"
                style={{
                  maxHeight: 180,
                  overflow: "auto",
                  paddingLeft: "12px",
                }}
              >
                {record.client_emails &&
                  record.client_emails.length > 0 &&
                  record.client_emails.map((eachMail, key) => (
                    <div>
                      <div>
                        <span>{`${key + 1}. `}</span>
                        <span>
                          <strong>
                            {checkValue(eachMail.email)
                              ? eachMail.email
                              : "N.A."}
                          </strong>
                        </span>
                      </div>
                    </div>
                  ))}
                {record.client_emails.length === 0 && (
                  <div>
                    <p>N.A.</p>
                  </div>
                )}
              </div>
            );
          },
        },

        {
          title: "ACTIONS",
          dataIndex: "application_number",
          className: "manage-trademark-column-action",
          key: "actions",
          width: "10%",
          render: (application_number, record) => {
            return (
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  margin: "8px",
                }}
                onClick={() => {
                  this.props.setStoreData({
                    selectedEmailDetail: record,
                  });
                  this.changeStateValue_L1("modalsVisibility", "detail", true);
                }}
              >
                <i className="fa fa-sliders" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>Manage</Text>
              </Button>
            );
          },
        },
      ];
    }

    const proprietors = this.props.filters?.PROPRIETORFilterData;
    const inventors = this.props.filters?.INVENTORFilterData;
    const applicants = this.props.filters?.APPLICANTFilterData;

    const selectedFiltersObj = this.state.selectedFilters;

    let proprietorsMenu = (
      <FilterSearchPaginate
        datasource={proprietors}
        filter_key={"trademarkProprietors"}
        req_filter={"PROPRIETOR"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={false}
        loader={this.props.filters[`PROPRIETORFilterStatus`] === LOADING}
        title={""}
        onSelect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "trademarkProprietors",
            event.selectedKeys
          )
        }
        onDeselect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "trademarkProprietors",
            event.selectedKeys
          )
        }
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "PROPRIETOR",
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.page + 1
          );
        }}
        searchPlaceholder={"Search Your Proprietors"}
        searchValue={
          this.state?.filterConfig["PROPRIETOR"]?.filter_contains_term
        }
      />
    );

    let inventorsMenu = (
      <FilterSearchPaginate
        datasource={inventors}
        filter_key={"patentInventors"}
        req_filter={"INVENTOR"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={false}
        loader={this.props.filters[`INVENTORFilterStatus`] === LOADING}
        title={""}
        onSelect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "patentInventors",
            event.selectedKeys
          )
        }
        onDeselect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "patentInventors",
            event.selectedKeys
          )
        }
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "INVENTOR",
            this.state?.filterConfig[`${"INVENTOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"INVENTOR"}`]?.page + 1
          );
        }}
        searchPlaceholder={"Search Inventors"}
        searchValue={this.state?.filterConfig["INVENTOR"]?.filter_contains_term}
      />
    );

    let applicantsMenu = (
      <FilterSearchPaginate
        datasource={applicants}
        filter_key={"patentApplicants"}
        req_filter={"APPLICANT"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={false}
        loader={this.props.filters[`APPLICANTFilterStatus`] === LOADING}
        title={""}
        onSelect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "patentApplicants",
            event.selectedKeys
          )
        }
        onDeselect={(event) =>
          this.changeStateValue_L1(
            "selectedFilters",
            "patentApplicants",
            event.selectedKeys
          )
        }
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "APPLICANT",
            this.state?.filterConfig[`${"APPLICANT"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"APPLICANT"}`]?.page + 1
          );
        }}
        searchPlaceholder={"Search Applicants"}
        searchValue={
          this.state?.filterConfig["APPLICANT"]?.filter_contains_term
        }
      />
    );

    const manageClientEmailRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows,
          add_trademark_id: selectedRows.map((data) => data.id),
          add_application_no: selectedRows.map(
            (data) => data.application_number
          ),
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Emails",
            description:
              "Click on the arrow near the select all checkbox to deselect all emails",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectedRows: [],
                  selectAll: false,
                  add_trademark_id: [],
                  add_application_no: [],
                });
              },
            },
          ]
        : false,
      className: "manage-trademark-column",

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let height = "calc(100vh - 0px)";

    const renderTable = (key) => {
      if (
        ["TRADEMARK", "CUSTOM_TRADEMARK", "INTERNATIONAL_TRADEMARK"].includes(
          key
        )
      ) {
        return (
          <Table
            rowKey={(record) => record.id}
            // bordered
            rowSelection={manageClientEmailRowSelection}
            columns={manageClientEmailColumns}
            dataSource={
              this.props["TRADEMARK"]?.clientEmail &&
              this.props["TRADEMARK"]?.clientEmail.data
            }
            pagination={{
              pageSize: 100,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              current: this.state.pageNumber,
              total:
                this.props["TRADEMARK"]?.clientEmail &&
                this.props["TRADEMARK"]?.clientEmail.count,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} `,
              onChange: this.handleNextpage,
            }}
            scroll={{ y: "calc(100vh - 340px)" }}
            loading={
              this.props["TRADEMARK"]?.clientEmailStatus === LOADING
                ? true
                : false
            }
          />
        );
      }
      if (["PATENT", "CUSTOM_PATENT", "INTERNATIONAL_PATENT"].includes(key)) {
        return (
          <Table
            rowKey={(record) => record.id}
            // bordered
            rowSelection={manageClientEmailRowSelection}
            columns={manageClientEmailColumns}
            dataSource={
              this.props["PATENT"]?.clientEmail &&
              this.props["PATENT"]?.clientEmail.data
            }
            pagination={{
              pageSize: 100,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              current: this.state.pageNumber,
              total:
                this.props["PATENT"]?.clientEmail &&
                this.props["PATENT"]?.clientEmail.count,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} `,
              onChange: this.handleNextpage,
            }}
            scroll={{ y: "calc(100vh - 340px)" }}
            loading={
              this.props["PATENT"]?.clientEmailStatus === LOADING ? true : false
            }
          />
        );
      } else {
        return (
          <Table
            rowKey={(record) => record.id}
            // bordered
            rowSelection={manageClientEmailRowSelection}
            columns={manageClientEmailColumns}
            dataSource={
              this.props[key]?.clientEmail && this.props[key]?.clientEmail.data
            }
            pagination={{
              pageSize: 100,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              current: this.state.pageNumber,
              total:
                this.props[key]?.clientEmail &&
                this.props[key]?.clientEmail.count,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} `,
              onChange: this.handleNextpage,
            }}
            scroll={{ y: "calc(100vh - 340px)" }}
            loading={
              this.props[key]?.clientEmailStatus === LOADING ? true : false
            }
          />
        );
      }
    };

    let popovercontent = (
      <>
        {" "}
        <span>
          <Input
            value={this.state.file_name}
            prefix={<FileTextOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="File Name"
            style={{
              margin: "0px 16px",
              width: "200px",
            }}
            onChange={(e) => {
              this.setState({ file_name: e?.target?.value });
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                event.stopPropagation(); // Prevent arrow keys from propagating
              }
            }}
          />
        </span>
        <Button
          type="dashed"
          key="submit"
          htmlType="submit"
          onClick={(event) => this.createReport(event)}
          style={{ margin: "0px 10px" }}
        >
          <i
            className="fa fa-download"
            style={{ padding: "0px 5px" }}
            aria-hidden="true"
          ></i>
        </Button>
      </>
    );

    const reportButton = (
      <Badge
        count={
          this.state?.selectAll ? "All" : this.state?.selectedRowKeys?.length
        }
        style={{ marginTop: "5px" }}
      >
        <Popover content={popovercontent} title="Report">
          <Button type="primary" key="submit" htmlType="submit">
            <i
              className="fa fa-download"
              style={{ padding: "0px 5px" }}
              aria-hidden="true"
            ></i>
            Report
          </Button>
        </Popover>
      </Badge>
    );

    return (
      <Layout style={{ background: "white", padding: "16px 16px 0px 16px" }}>
        <Row type="flex" align="middle">
          <Col>
            <Title level={3}>Client Emails</Title>
          </Col>

          <Col
            style={{
              marginLeft: "auto",
            }}
          >
            <Button
              type="primary"
              style={{
                margin: "12px",
              }}
              onClick={() =>
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "addClientEmail",
                  true
                )
              }
            >
              <CloudUploadOutlined
                style={{
                  fontSize: "20px",
                }}
              />
              Bulk Upload
            </Button>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={
            this.props?.userSubscriptions?.manager &&
            this.props?.userSubscriptions?.manager.length > 0
              ? this.props?.userSubscriptions?.manager.length > 0 &&
                (this.props?.userSubscriptions?.manager?.includes(
                  "TRADEMARK"
                ) ||
                  this.props?.userSubscriptions?.manager?.includes(
                    "CUSTOM_TRADEMARK"
                  ) ||
                  this.props?.userSubscriptions?.manager?.includes(
                    "INTERNATIONAL_TRADEMARK"
                  ))
                ? TRADEMARK
                : this.props?.userSubscriptions?.manager.length > 0 &&
                  (this.props?.userSubscriptions?.manager?.includes("PATENT") ||
                    this.props?.userSubscriptions?.manager?.includes(
                      "CUSTOM_PATENT"
                    ) ||
                    this.props?.userSubscriptions?.manager?.includes(
                      "INTERNATIONAL_PATENT"
                    ))
                ? PATENT
                : this.props?.userSubscriptions?.watch?.includes("TM Protect")
                ? TM_PROTECT
                : JOURNAL_WATCH
              : TRADEMARK
          }
          activeKey={this.props.activeTab}
          onChange={this.handleTabChange}
        >
          {(this.props.userSubscriptions.manager.length > 0 &&
            (this.props?.userSubscriptions?.manager?.includes("TRADEMARK") ||
              this.props?.userSubscriptions?.manager?.includes(
                "CUSTOM_TRADEMARK"
              ) ||
              this.props?.userSubscriptions?.manager?.includes(
                "INTERNATIONAL_TRADEMARK"
              )) && (
              <TabPane
                tab={"Trademark Manager"}
                key={TRADEMARK}
                style={{ margin: "0px" }}
              >
                <Content style={{ margin: "0px" }}>
                  <div
                    className="
                vertical_scroll"
                    style={{
                      padding: "8px 16px 16px 16px",
                      background: "#ffffff",
                      overflow: "auto",
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col span={9}>
                        <Menu
                          selectedKeys={[]}
                          className="filter-list"
                          mode="horizontal"
                        >
                          <Menu.Item
                            key="contentTerm"
                            className="filter-list-item apply-filter"
                          >
                            <Input
                              placeholder="Search"
                              suffix={
                                <Tooltip title="Search - Application Number, Applied for, Email, Person Name & Company Name">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      margin: 0,
                                    }}
                                  />
                                </Tooltip>
                              }
                              onChange={(event) =>
                                this.changeStateValue_L1(
                                  "selectedFilters",
                                  "containsTerm",
                                  event.target.value
                                )
                              }
                              value={this.state?.selectedFilters?.containsTerm}
                              allowClear
                              style={{ width: 200 }}
                              onKeyDown={(event) => {
                                if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                                  event.stopPropagation(); // Prevent arrow keys from propagating
                                }
                              }}
                            />
                          </Menu.Item>
                          <Menu.Item
                            key="applyFilter"
                            className="filter-list-item apply-filter"
                          >
                            <Button
                              type="primary"
                              onClick={() => this.applyFilter()}
                            >
                              <i className="fa fa-filter" aria-hidden="true" />
                              <Text
                                style={{ color: "#fff", marginLeft: "5px" }}
                              >
                                Apply Filter
                              </Text>
                            </Button>
                          </Menu.Item>
                          <Menu.Item
                            key="resetFilter"
                            className="filter-list-item apply-filter"
                            onClick={() => this.resetFilter()}
                          >
                            <span style={{ padding: "0px 5px" }}>RESET</span>
                          </Menu.Item>
                        </Menu>
                      </Col>
                      <Col>
                        <Space>
                          <Tooltip title="Deselect All">
                            <Button
                              danger
                              onClick={() => {
                                this.setState({
                                  selectedRowKeys: [],
                                  selectAll: false,
                                  selectedRows: [],
                                });
                              }}
                              // icon={"minus-circle"}
                              style={{
                                margin: "0px 8px",
                                padding: "0px 8px",
                              }}
                              disabled={
                                !(
                                  this.state?.selectAll ||
                                  this.state?.selectedRows?.length
                                )
                              }
                            >
                              <div style={{ display: "flex" }}>
                                <MinusCircleOutlined
                                  style={{ margin: "0", fontSize: "15px" }}
                                  onClick={() => {
                                    this.setState({
                                      selectedRowKeys: [],
                                      selectAll: false,
                                      selectedRows: [],
                                    });
                                  }}
                                />
                              </div>
                            </Button>
                          </Tooltip>
                          {this.state?.selectAll ||
                          this.state?.selectedRows?.length ? (
                            <>
                              <Space>
                                {reportButton}
                                <Badge
                                  count={
                                    this.state?.selectAll
                                      ? "All"
                                      : this.state?.selectedRowKeys?.length
                                  }
                                  style={{
                                    marginTop: "5px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FDE5E7",
                                      color: "red",
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "0px 8px",
                                    }}
                                    danger
                                    loading={
                                      this.props.deleteClientEmailStatus ===
                                      LOADING
                                    }
                                    onClick={() => this.deleteEmailEntityData()}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        fontSize: "18px",
                                        margin: 0,
                                        color: "red",
                                      }}
                                    />
                                    Delete
                                  </Button>
                                </Badge>
                              </Space>
                            </>
                          ) : null}

                          <Select
                            value={this.props.selectedSubscription}
                            style={{ width: "180px", margin: "0px 8px" }}
                            onChange={this.handleSubscriptionChange}
                          >
                            {this.props?.userSubscriptions?.manager?.includes(
                              TRADEMARK
                            ) && <Option value={TRADEMARK}>Trademark</Option>}
                            {this.props?.userSubscriptions?.manager?.includes(
                              CUSTOM_TRADEMARK
                            ) && (
                              <Option value={CUSTOM_TRADEMARK}>
                                Custom trademark
                              </Option>
                            )}
                            {this.props?.userSubscriptions?.manager?.includes(
                              CUSTOM_TRADEMARK
                            ) && (
                              <Option value={INTERNATIONAL_TRADEMARK}>
                                International trademark
                              </Option>
                            )}
                          </Select>
                          {this.props.selectedSubscription === "TRADEMARK" && (
                            <Select
                              defaultValue="Portfolio trademark"
                              value={this.props.mmType}
                              style={{ width: "180px", margin: "0px 8px" }}
                              onChange={this.handleMMtypeChange}
                            >
                              <Option value="Portfolio trademark">
                                PORTFOLIO TM
                              </Option>
                              <Option value="Third party Opposed trademark">
                                THIRD PARTY TM
                              </Option>
                              <Option value="Status tracker">
                                STATUS TRACKER
                              </Option>
                            </Select>
                          )}
                        </Space>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col span={24}>
                        <div className="m-top-20 client-email">
                          {renderTable("TRADEMARK")}
                        </div>
                      </Col>
                    </Row>
                    <AddClientEmails
                      visible={this.state.modalsVisibility.addClientEmail}
                      onCloseModal={() => {
                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "addClientEmail",
                          false
                        );
                      }}
                      onConfirm={this.resetState}
                      product={MANAGER}
                    />
                    <EditClientEmail
                      visible={this.state?.modalsVisibility?.edit}
                      onCloseModal={() => {
                        this.resetState();

                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "edit",
                          false
                        );
                      }}
                      // product={MANAGER}

                      selectedSubscription={this.props?.selectedSubscription}
                      mmType={this.props?.mmType}
                    />
                  </div>
                </Content>
              </TabPane>
            )) ||
            null}
          {(this.props.userSubscriptions.manager.length > 0 &&
            (this.props?.userSubscriptions?.manager?.includes("PATENT") ||
              this.props?.userSubscriptions?.manager?.includes(
                "CUSTOM_PATENT"
              ) ||
              this.props?.userSubscriptions?.manager?.includes(
                "INTERNATIONAL_PATENT"
              )) && (
              <TabPane
                tab={"Patent Manager"}
                key={PATENT}
                style={{ margin: "0px" }}
              >
                <Content style={{ margin: "0px" }}>
                  <div
                    className="tab-content-container vertical_scroll"
                    style={{ height: `${height}` }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col span={9}>
                        <Menu
                          selectedKeys={[]}
                          className="filter-list"
                          mode="horizontal"
                        >
                          <Menu.Item
                            key="contentTerm"
                            className="filter-list-item apply-filter"
                          >
                            <Input
                              placeholder="Search"
                              suffix={
                                <Tooltip title="Search - Application Number, Title, Email, Person Name & Company Name">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "rgba(0,0,0,.45)",
                                      margin: 0,
                                    }}
                                  />
                                </Tooltip>
                              }
                              onChange={(event) =>
                                this.changeStateValue_L1(
                                  "selectedFilters",
                                  "containsTerm",
                                  event.target.value
                                )
                              }
                              value={this.state?.selectedFilters?.containsTerm}
                              allowClear
                              style={{ width: 200 }}
                              onKeyDown={(event) => {
                                if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                                  event.stopPropagation(); // Prevent arrow keys from propagating
                                }
                              }}
                            />
                          </Menu.Item>
                          <Menu.Item
                            key="applyFilter"
                            className="filter-list-item apply-filter"
                          >
                            <Button
                              type="primary"
                              onClick={() => this.applyFilter()}
                            >
                              <i className="fa fa-filter" aria-hidden="true" />
                              <Text
                                style={{ color: "#fff", marginLeft: "5px" }}
                              >
                                Apply Filter
                              </Text>
                            </Button>
                          </Menu.Item>
                          <Menu.Item
                            key="resetFilter"
                            className="filter-list-item apply-filter"
                            onClick={() => this.resetFilter()}
                          >
                            <span style={{ padding: "0px 5px" }}>RESET</span>
                          </Menu.Item>
                        </Menu>
                      </Col>
                      <Col>
                        <Space>
                          <Tooltip title="Deselect All">
                            <Button
                              danger
                              onClick={() => {
                                this.setState({
                                  selectedRowKeys: [],
                                  selectAll: false,
                                  selectedRows: [],
                                });
                              }}
                              // icon={"minus-circle"}
                              style={{
                                margin: "0px 8px",
                                padding: "0px 8px",
                              }}
                              disabled={
                                !(
                                  this.state?.selectAll ||
                                  this.state?.selectedRows?.length
                                )
                              }
                            >
                              <div style={{ display: "flex" }}>
                                <MinusCircleOutlined
                                  style={{ margin: "0", fontSize: "15px" }}
                                  onClick={() => {
                                    this.setState({
                                      selectedRowKeys: [],
                                      selectAll: false,
                                      selectedRows: [],
                                    });
                                  }}
                                />
                              </div>
                            </Button>
                          </Tooltip>
                          {this.state?.selectAll ||
                          this.state?.selectedRows?.length ? (
                            <>
                              <Space>
                                {reportButton}
                                <Badge
                                  count={
                                    this.state?.selectAll
                                      ? "All"
                                      : this.state?.selectedRowKeys?.length
                                  }
                                  style={{
                                    marginTop: "5px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FDE5E7",
                                      color: "red",
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "0px 8px",
                                    }}
                                    danger
                                    loading={
                                      this.props.deleteClientEmailStatus ===
                                      LOADING
                                    }
                                    onClick={() => this.deleteEmailEntityData()}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        fontSize: "18px",
                                        margin: 0,
                                        color: "red",
                                      }}
                                    />
                                    Delete
                                  </Button>
                                </Badge>
                              </Space>
                            </>
                          ) : null}
                          <Select
                            value={this.props.selectedSubscription}
                            style={{ width: 180 }}
                            onChange={this.handleSubscriptionChange}
                          >
                            <Option key={PATENT} title={PATENT} value={PATENT}>
                              Patent
                            </Option>
                            <Option
                              key={CUSTOM_PATENT}
                              title={CUSTOM_PATENT}
                              value={CUSTOM_PATENT}
                            >
                              Custom Patent
                            </Option>
                            <Option
                              key={INTERNATIONAL_PATENT}
                              title={INTERNATIONAL_PATENT}
                              value={INTERNATIONAL_PATENT}
                            >
                              International Patent
                            </Option>
                          </Select>
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="m-top-20 client-email">
                          {renderTable("PATENT")}
                        </div>
                      </Col>
                    </Row>
                    <AddClientEmails
                      visible={this.state.modalsVisibility.addClientEmail}
                      onCloseModal={() => {
                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "addClientEmail",
                          false
                        );
                      }}
                      onConfirm={this.resetState}
                      product={MANAGER}
                    />
                    <EditClientEmail
                      visible={this.state?.modalsVisibility?.edit}
                      onCloseModal={() => {
                        this.resetState();

                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "edit",
                          false
                        );
                      }}
                      // product={MANAGER}

                      selectedSubscription={this.props?.selectedSubscription}
                      mmType={this.props?.mmType}
                    />
                  </div>
                </Content>
              </TabPane>
            )) ||
            null}
          {(this.props.userSubscriptions.watch.length > 0 &&
            (this.props?.userSubscriptions?.watch?.includes("TM Protect") ||
              this.props?.userSubscriptions?.watch?.includes(
                "Journal Watch"
              )) && (
              <TabPane
                tab={"MikeTM Watch"}
                key={"JOURNAL_WATCH"}
                style={{ margin: "0px" }}
              >
                <Content style={{ margin: "0px" }}>
                  <div
                    className="tab-content-container vertical_scroll"
                    style={{ height: `${height}` }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col span={9}>
                        <Menu
                          selectedKeys={[]}
                          className="filter-list"
                          mode="horizontal"
                        >
                          <Menu.Item
                            key="contentTerm"
                            className="filter-list-item apply-filter"
                          >
                            {this.props.selectedSubscription ===
                            JOURNAL_WATCH ? (
                              <Input
                                placeholder="Search"
                                suffix={
                                  <Tooltip title="Search - Application Number, Applied for, Email, Person Name & Company Name">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "rgba(0,0,0,.45)",
                                        margin: 0,
                                      }}
                                    />
                                  </Tooltip>
                                }
                                onChange={(event) =>
                                  this.changeStateValue_L1(
                                    "selectedFilters",
                                    "containsTerm",
                                    event.target.value
                                  )
                                }
                                value={
                                  this.state?.selectedFilters?.containsTerm
                                }
                                allowClear
                                style={{ width: 200 }}
                                onKeyDown={(event) => {
                                  if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                                    event.stopPropagation(); // Prevent arrow keys from propagating
                                  }
                                }}
                              />
                            ) : (
                              <Input
                                placeholder="Search"
                                suffix={
                                  <Tooltip title="Search - Term, Email, Person Name & Company Name.">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "rgba(0,0,0,.45)",
                                        margin: 0,
                                      }}
                                    />
                                  </Tooltip>
                                }
                                onChange={(event) =>
                                  this.changeStateValue_L1(
                                    "selectedFilters",
                                    "containsTerm",
                                    event.target.value
                                  )
                                }
                                value={
                                  this.state?.selectedFilters?.containsTerm
                                }
                                allowClear
                                style={{ width: 200 }}
                                onKeyDown={(event) => {
                                  if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                                    event.stopPropagation(); // Prevent arrow keys from propagating
                                  }
                                }}
                              />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="applyFilter"
                            className="filter-list-item apply-filter"
                          >
                            <Button
                              type="primary"
                              onClick={() => this.applyFilter()}
                            >
                              <i className="fa fa-filter" aria-hidden="true" />
                              <Text
                                style={{ color: "#fff", marginLeft: "5px" }}
                              >
                                Apply Filter
                              </Text>
                            </Button>
                          </Menu.Item>
                          <Menu.Item
                            key="resetFilter"
                            className="filter-list-item apply-filter"
                            onClick={() => this.resetFilter()}
                          >
                            <span style={{ padding: "0px 5px" }}>RESET</span>
                          </Menu.Item>
                        </Menu>
                      </Col>
                      <Col>
                        <Space>
                          <Tooltip title="Deselect All">
                            <Button
                              danger
                              onClick={() => {
                                this.setState({
                                  selectedRowKeys: [],
                                  selectAll: false,
                                  selectedRows: [],
                                });
                              }}
                              // icon={"minus-circle"}
                              style={{
                                margin: "0px 8px",
                                padding: "0px 8px",
                              }}
                              disabled={
                                !(
                                  this.state?.selectAll ||
                                  this.state?.selectedRows?.length
                                )
                              }
                            >
                              <div style={{ display: "flex" }}>
                                <MinusCircleOutlined
                                  style={{ margin: "0", fontSize: "15px" }}
                                  onClick={() => {
                                    this.setState({
                                      selectedRowKeys: [],
                                      selectAll: false,
                                      selectedRows: [],
                                    });
                                  }}
                                />
                              </div>
                            </Button>
                          </Tooltip>
                          {this.state?.selectAll ||
                          this.state?.selectedRows?.length ? (
                            <>
                              <Space>
                                {reportButton}
                                <Badge
                                  count={
                                    this.state?.selectAll
                                      ? "All"
                                      : this.state?.selectedRowKeys?.length
                                  }
                                  style={{
                                    marginTop: "5px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FDE5E7",
                                      color: "red",
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "0px 8px",
                                    }}
                                    danger
                                    loading={
                                      this.props.deleteClientEmailStatus ===
                                      LOADING
                                    }
                                    onClick={() => this.deleteEmailEntityData()}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        fontSize: "18px",
                                        margin: 0,
                                        color: "red",
                                      }}
                                    />
                                    Delete
                                  </Button>
                                </Badge>
                              </Space>
                            </>
                          ) : null}

                          <Select
                            value={this.props.selectedSubscription}
                            style={{ width: 180 }}
                            onChange={this.handleSubscriptionChange}
                          >
                            {this.props?.userSubscriptions?.watch?.includes(
                              "Journal Watch"
                            ) && (
                              <Option
                                key={JOURNAL_WATCH}
                                title={JOURNAL_WATCH}
                                value={JOURNAL_WATCH}
                              >
                                Journal Watch
                              </Option>
                            )}
                            {this.props?.userSubscriptions?.watch?.includes(
                              "TM Protect"
                            ) && (
                              <Option
                                key={TM_PROTECT}
                                title={TM_PROTECT}
                                value={TM_PROTECT}
                              >
                                TM Protect
                              </Option>
                            )}
                          </Select>
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="m-top-20 client-email">
                          {renderTable(this.props.selectedSubscription)}
                        </div>
                      </Col>
                    </Row>
                    <AddClientEmails
                      visible={this.state.modalsVisibility.addClientEmail}
                      onCloseModal={() => {
                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "addClientEmail",
                          false
                        );
                      }}
                      onConfirm={this.resetState}
                      product={MANAGER}
                    />
                    <EditClientEmail
                      visible={this.state?.modalsVisibility?.edit}
                      onCloseModal={() => {
                        this.resetState();

                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "edit",
                          false
                        );
                      }}
                      // product={MANAGER}

                      selectedSubscription={this.props?.selectedSubscription}
                      mmType={this.props?.mmType}
                    />
                  </div>
                </Content>
              </TabPane>
            )) ||
            null}
        </Tabs>
        <ClientEmailDetails
          visible={this.state?.modalsVisibility?.detail}
          onCancel={() =>
            this.changeStateValue_L1("modalsVisibility", "detail", false)
          }
          emailEntity={this.getEmailEntity}
          setStore={(obj) => {
            this.props.setStoreData({
              editEmailData: { ...obj },
            });
          }}
          handleDelete={this.handleDelete}
          selectedSubscription={this.props?.selectedSubscription}
          mmType={this.props?.mmType}
          deleteClientEmailStatus={this.props?.deleteClientEmailStatus}
          loading={
            this.props?.deleteClientEmailStatus === "LOADING" ||
            this.props?.editClientEmailStatus === "LOADING" ||
            this.props?.clientEmailStatus === "LOADING" ||
            this.props[this.props.selectedSubscription]?.clientEmailStatus ===
              "LOADING"
          }
          resetState={this.resetState}
        />
      </Layout>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  getClientEmails,
  createCEReport,
  markDownload,
  logOut,
  setStoreData,
  deleteClientEmail,
};

function mapStateToProps(state) {
  const {
    selectedSubscription,
    mmType,
    activeTab,
    TRADEMARK,
    PATENT,
    JOURNAL_WATCH,
    TM_PROTECT,
    filters,

    manageClientEmailLastAppliedFilters,
    pageNumber,

    clientEmailStatus,

    createClientEmailStatus,
    editClientEmailStatus,
    deleteClientEmailStatus,
    createCEReportStatus,
    selectedEmails,
    selectedEmailDetail,
  } = state.clientEmailState;

  const { teamMember, teamMemberStatus } = state.profile;

  const { userSubscriptions, userSubscriptionStatus } = state.userSubscriptions;

  return deepFreeze({
    activeTab,
    selectedSubscription,
    mmType,
    TRADEMARK,
    PATENT,
    JOURNAL_WATCH,
    TM_PROTECT,
    filters,
    clientEmailStatus,
    lastAppliedFilters: manageClientEmailLastAppliedFilters,
    pageNumber,
    deleteClientEmailStatus,
    createCEReportStatus,
    teamMember,
    teamMemberStatus,
    selectedEmails,
    selectedEmailDetail,
    userSubscriptions,
    userSubscriptionStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmails);
