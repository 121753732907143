//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _ from "lodash";
import Axios from "axios";
import { DownOutlined, TagTwoTone, DeleteOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  Checkbox,
  message,
  notification,
  Typography,
  Select,
  Tooltip,
  Badge,
} from "antd";

//--------MANGER: ACTIONS--------
import {
  getManageTrademark,
  postManageTrademark,
  postDownloadFile,
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import {
  markDownload,
  getTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
  MANAGER,
} from "../../../constants/commonConstants/generalConstants";

//--------MANAGER CONSTANTS--------
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
  MT_defaultSelectedSetters,
} from "./../../../constants/managerConstants/trademarkConstants";

import { changeStateValue_L1 } from "./../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------IP-SUITE COMMON COMPONENTS--------
import AddTrademarks from "../../commonComponents/Modals/AddTrademarks";
import TagsDrawer from "../../commonComponents/Drawers/Tags";
import DownloadPortfolioDrawer from "../../commonComponents/Drawers/DownloadPortfolio";

//--------Manager: Components--------
import ManageTrademarkDrawer from "../trademark/Manage-Trademark-Drawer";

//--------RT_CONFIG--------
import { MGR_RT_URL } from "../../../configs/rootConfigs";

//--------HISTORY--------
import { history } from "../../../history";
import { TM } from "../../../constants/managerConstants/managerConstants";
import { standardizeDate } from "../../../functions/commonFunctions/dateFunctions";
import { getTags } from "../../../redux/actions/commonActions/actions";
import FilterSearchBasic from "../../commonComponents/FilterComponents/FilterSearchBasic";
// import TagFilterSearchBasic from "../../commonComponents/FilterComponents/TagFilterSearchBasic";

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

class ManageTrademark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      management_mark_id: "",
      editingKey: "",
      selectedSetters: MT_defaultSelectedSetters,
      add_trademark_id: [],
      tag_color: "",
      add_application_no: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      noTagToggle: false,
      searchFilters: TM_DefaultSearchFilters,
      selectedFilters: this.props.lastAppliedFilters,
      pageNumber: this.props.pageNumber,
      drawersVisibility: {
        manageTrademark: false,
        manageTags: false,
        downloadPortfolio: false,
      },
      modalsVisibility: {
        addTrademarks: false,
      },
      value: this.props.manageTrademarkType,
    };

    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.manageTrademark === null) {
        this.props.getManageTrademark({
          mmType: "trademark",
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.props.getTags();
      }
      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }

  handleChange = (value) => {
    this.props.getManageTrademark({
      mmType: value,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: this.props.pageNumber,
    });
    this.setState({
      value: value,
      selectedFilters: TM_DefaultSelectedFilters,
      searchFilters: TM_DefaultSearchFilters,
      add_application_no: [],
      add_trademark_id: [],
      selectedRowKeys: [],
      selectAll: false,
    });
  };

  async deleteMarks() {
    if (this.state.add_trademark_id.length > 0) {
      await this.props.postManageTrademark({
        mmType: this.state.value,
        setters: {},
        select_all: this.state.selectAll,
        delete_flag: true,
        filterFlag: this.props.lastAppliedFilters.filterFlag,
        filters: this.props.lastAppliedFilters,
        trademarks: this.state.add_application_no,
        application_number: null,
      });

      notification[
        this.props.deleteManageTrademarkStatus === SUCCESS
          ? "success"
          : this.props.deleteManageTrademarkStatus === ERROR
            ? "error"
            : "smile"
      ]({
        message: "Delete Trademark",
        description:
          this.props.deleteManageTrademarkStatus === SUCCESS
            ? "Successfully Deleted Trademark"
            : this.props.deleteManageTrademarkStatus === ERROR
              ? "Unable to Deleted trademark. Please try again later!"
              : "",
      });
      this.props.getManageTrademark({
        mmType: this.state.value,
        filterFlag: this.props.lastAppliedFilters.filterFlag,
        filters: this.props.lastAppliedFilters,
        pageNumber: this.props.pageNumber,
      });
      this.setState({
        add_application_no: [],
        add_trademark_id: [],
        selectedRowKeys: [],
        selectedRows: [],
        selectAll: false,
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  editTrademark(data, selected_tags, single, tags) {
    this.setState({
      editingKey: data.id,
      application_number: data.application_number,
      [selected_tags]: {
        ...this.state[selected_tags],
        [single]: {
          ...this.state[selected_tags][single],
          [tags]: data.tags.map((tagsData) => `${tagsData.id}`),
        },
      },
      drawersVisibility: {
        ...this.state.drawersVisibility,
        manageTrademark: data,
      },
    });
  }

  applyFilter() {
    const {
      trademarkClass,
      trademarkTags,
      trademarkProprietors,
      containsTerm,
      trademarkNOtags,
    } = this.state?.selectedFilters;
    if (
      trademarkClass?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkNOtags?.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });
      this.props.getManageTrademark({
        mmType: this.state.value,
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  resetFilter() {
    this.setState({ noTagToggle: false });
    // this.setState({
    //   tag_color: (document.getElementById("tag-color").style.color = "#595959"),
    // });
    const {
      trademarkClass,
      trademarkTags,
      trademarkProprietors,
      containsTerm,
      trademarkNOtags,
    } = this.props?.lastAppliedFilters || {};
    if (
      trademarkClass?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkNOtags?.length > 0
    ) {
      this.setState({
        searchFilters: TM_DefaultSearchFilters,
        selectedFilters: {
          ...this.state.selectedFilters,
          ...TM_DefaultSelectedFilters,
          filterFlag: false,
        },
        pageNumber: 1,
      });
      this.props.getManageTrademark({
        mmType: this.state.value,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Applied");
    }
  }
  //   *********************************NO Tags ********************************//

  handleNoTag(e) {
    this.setState({ noTagToggle: !this.state.noTagToggle });
  }

  handleNextpage = (pageNumber) => {
    this.props.getManageTrademark({
      mmType: this.state.value,
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  submitFile = (event, application_number) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file[0]);
    formData.append("application_number", application_number);
    Axios.post(`${MGR_RT_URL}/test-upload/`, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // handle your response;
      })
      .catch((error) => {
        // handle your error
      });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { searchFilters, selectedFilters } = this.state;

    const manageTrademarkColumns = [
      {
        title: "APPLICATION",
        dataIndex: "application_number",
        className: "manage-trademark-column",
        render: (text, record) => (
          <p
            className="application-text text-camelCase"
            onClick={() => this.handleTrademarkProfile(record)}
          >
            {checkValue(text) ? text : "N.A."}
          </p>
        ),
      },

      {
        title: "IMAGE",
        dataIndex: "associated_image",
        className: "manage-trademark-column",
        render: (text) => {
          return (
            <>
              {checkValue(text) ? (
                <img className="trademark-image" src={text} alt="" />
              ) : (
                <p className="text-camelCase">No Image Available</p>
              )}
            </>
          );
        },
      },

      {
        title: "APPLIED FOR",
        dataIndex: "applied_for",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "CLASS",
        dataIndex: "associated_classes",
        className: "manage-trademark-column-class",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "PROPRIETOR",
        dataIndex: "proprietors",
        className: "manage-trademark-column",
        render: (proprietors) => {
          return (
            <div>
              {proprietors.map((proprietorData) => (
                <p key={proprietorData.id} className="text-camelCase">
                  {checkValue(proprietorData.name)
                    ? proprietorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "TAGS",
        width: 150,
        dataIndex: "tags",
        className: "manage-trademark-column",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "UPLOADED DOCUMENTS",
        dataIndex: "uploaded_documents",
        className: "manage-trademark-column",
        render: (documents, record) => {
          return (
            <div>
              {documents.length > 0
                ? documents.map((eachDocument) => (
                    <Tooltip
                      placement="left"
                      title={
                        <div>
                          <div>
                            <strong>File Name:</strong> {eachDocument.title}
                          </div>
                          <div>
                            <strong>Uploaded By:</strong>{" "}
                            {eachDocument.uploaded_by}
                          </div>
                          <div>
                            <strong>Last Modified:</strong>
                            <br />
                            {standardizeDate(eachDocument.modified)}
                          </div>
                        </div>
                      }
                    >
                      <div
                        key={eachDocument.title}
                        className="Clickable-Link"
                        onClick={() =>
                          this.props.postDownloadFile({
                            applicationNumber: record.application_number,
                            documentFor: TM,
                            fileName: eachDocument.title,
                          })
                        }
                      >
                        {eachDocument.title}
                      </div>
                    </Tooltip>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "application_number",
        className: "manage-trademark-column-action",
        key: "actions",
        render: (application_number, record) => {
          return (
            <Button
              type="primary"
              style={{
                marginRight: 10,
                background: "#59c4af",
                borderColor: "#59c4af",
              }}
              onClick={() =>
                this.editTrademark(record, "selectedSetters", "single", "tags")
              }
            >
              Edit
            </Button>
          );
        },
      },
    ];

    const manageTrademarkRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_trademark_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_trademark_id]),
      //         add_application_no:  _.uniq([...selectedRows.map(data=> data.application_number), ...this.state.add_application_no]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            add_application_no: newSelected.map((x) => x.application_number),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            add_application_no: newSelected.map((x) => x.application_number),
            selectedRows: newSelected,
          });
        }
      },
      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.manageTrademark && this.props.manageTrademark.trademarks;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
          add_trademark_id: data.map((x) => x.id),
          add_application_no: data.map((x) => x.application_number),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },
      // onSelect: (record, selected, selectedRows ) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: false,
                  add_trademark_id: [],
                  selectedRows: [],
                  add_application_no: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let tagsMenu = noFilterData;

    if (this.props.manageTrademarkStatus === SUCCESS) {
      const { classes, tags, proprietors } = this.props.manageTrademark.filters;

      if (classes !== null && classes.length > 0) {
        classesMenu = (
          <FilterSearchBasic
            data={classes}
            searchFilters={searchFilters}
            search_key={"classSearch"}
            filter_key={"trademarkClass"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"number"}
          />
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        proprietorsMenu = (
          <FilterSearchBasic
            data={proprietors}
            searchFilters={searchFilters}
            search_key={"proprietorSearch"}
            filter_key={"trademarkProprietors"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }

      if (tags !== null && tags.length > 0) {
        tagsMenu = (
          <FilterSearchBasic
            data={tags}
            searchFilters={searchFilters}
            search_key={"tagsSearch"}
            filter_key={"trademarkTags"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"noTag"}
            handleNoTag={this.handleNoTag}
            noTagToggle={this.state.noTagToggle}
          />
        );
      }
    }

    return (
      <>
        {/* Back */}
        <Row>
          <Col span={2} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={10}
            className="alignC"
            style={{ fontSize: "14px!important" }}
          >
            <Text strong style={{ margin: "0px", fontSize: "16px" }}>
              TOTAL TRADEMARK :{" "}
              {checkValue(this.props.manageTrademark)
                ? this.props.manageTrademark.count
                : 0}{" "}
            </Text>
          </Col>
          <Col span={12} className="alignR">
            <Select
              value={this.state.value}
              style={{ width: 180, margin: "0 10px" }}
              onChange={this.handleChange}
            >
              <Option key="trademark" title="trademark" value="trademark">
                Your Trademark
              </Option>
              <Option key="opposed" title="opposed" value="opposed">
                3rd Party Trademark
              </Option>
              <Option key="tracker" title="tracker" value="tracker">
                Status Tracker
              </Option>
            </Select>
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "manageTags",
                  true,
                )
              }
              style={{ background: "#59c4af", borderColor: "#59c4af" }}
            >
              Manage Tags
            </Button>
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "downloadPortfolio",
                  true,
                )
              }
              style={{ margin: "0 10px" }}
            >
              Download Portfolio
            </Button>
          </Col>
        </Row>
        {/* Back */}

        {/* Manage Trademarks filter */}
        <Row style={{ borderBottom: "1px solid #E9E9E9" }}>
          <Col span={14} className="alignC m-top-10">
            <Menu
              selectedKeys={[
                this.state.selectedFilters?.trademarkClass?.length > 0
                  ? "classesMenu"
                  : "",
                this.state.selectedFilters?.trademarkProprietors?.length > 0
                  ? "proprietorsMenu"
                  : "",
                this.state.selectedFilters?.trademarkTags?.length > 0
                  ? "tagsMenu"
                  : "",
                this.state.selectedFilters?.trademarkNOtags?.length > 0
                  ? "tagsMenu"
                  : "",
              ]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item key="classesMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => classesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "classesMenu")
                  }
                  open={this.state.open === "classesMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Class </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="proprietorsMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => proprietorsMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "proprietorsMenu")
                  }
                  open={this.state.open === "proprietorsMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Proprietor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>

              <Menu.Item key="tagsMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => tagsMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "tagsMenu")
                  }
                  open={this.state.open === "tagsMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Tags</span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>

              <Menu.Item
                key="contentTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L1(
                      "selectedFilters",
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={this.state?.selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                  onKeyDown={(event) => {
                    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                      event.stopPropagation(); // Prevent arrow keys from propagating
                    }
                  }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
          <Col
            span={10}
            className="alignR m-top-10"
            style={{ padding: "5px 0px" }}
          >
            <div style={{ display: "flex", float: "right" }}>
              {/* <Text style={{ padding: "0px 5px" }}>
                {this.state.selectAll
                  ? this.props.manageTrademark &&
                    this.props.manageTrademark.count
                  : _.uniq(this.state.add_trademark_id).length}{" "}
                Mark(s)
              </Text> */}
              <Badge
                count={
                  this.state.selectAll
                    ? "All"
                    : _.uniq(this.state.add_trademark_id).length
                }
              >
                <Button
                  style={{
                    backgroundColor: "#FDE5E7",
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  loading={this.props.deleteManageTrademarkStatus === LOADING}
                  onClick={() => this.deleteMarks()}
                  icon={
                    <DeleteOutlined
                      style={{ fontSize: "18px", color: "red" }}
                    />
                  }
                >
                  Delete Trademark
                </Button>
              </Badge>
              <Button
                type="primary"
                style={{ margin: "0 10px" }}
                onClick={() =>
                  this.changeStateValue_L1(
                    "modalsVisibility",
                    "addTrademarks",
                    true,
                  )
                }
              >
                Add Trademark
              </Button>
            </div>
          </Col>
        </Row>
        {/* Manage Trademarks filter */}

        {/* Manage Trademarks table */}
        <Row>
          <Col span={24}>
            <div className="m-top-20 manage-trademark">
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={manageTrademarkRowSelection}
                columns={manageTrademarkColumns}
                dataSource={
                  this.props.manageTrademark &&
                  this.props.manageTrademark.trademarks
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: this.state.pageNumber,
                  total:
                    this.props.manageTrademark &&
                    this.props.manageTrademark.count,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(100vh - 240px)" }}
                loading={
                  this.props.manageTrademarkStatus === LOADING ? true : false
                }
              />
            </div>
          </Col>
        </Row>
        {/* Manage Trademarks table */}

        {/* Manage Trademark Drawer */}
        {this.state.drawersVisibility.manageTrademark && (
          <ManageTrademarkDrawer
            visible={this.props.manageTrademark.trademarks.find(
              ({ application_number }) =>
                application_number === this.state.application_number,
            )}
            onCloseDrawer={() =>
              this.changeStateValue_L1(
                "drawersVisibility",
                "manageTrademark",
                false,
              )
            }
            editingKey={this.state.editingKey}
            selectedSetters={this.state.selectedSetters}
            application_number={this.state.application_number}
            selectAll={this.state.selectAll}
            mmType={this.state.value}
          />
        )}

        <AddTrademarks
          visible={this.state.modalsVisibility.addTrademarks}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "addTrademarks", false)
          }
          product={MANAGER}
        />

        <TagsDrawer
          visible={this.state.drawersVisibility.manageTags}
          onCloseDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "manageTags", false)
          }
        />

        <DownloadPortfolioDrawer
          visible={this.state.drawersVisibility.downloadPortfolio}
          onCloseDrawer={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "downloadPortfolio",
              false,
            )
          }
          product={"manager"}
          sub_product={"trademark"}
        />
      </>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  getManageTrademark,
  getTags,
  postManageTrademark,
  getTeamMember,
  postDownloadFile,
  markDownload,
  logOut,
};

function mapStateToProps(state) {
  const {
    manageTrademarkType,
    manageTrademark,
    manageTrademarkStatus,
    manageTrademarkLastAppliedFilters,
    manageTrademarkNumber,
    deleteManageTrademarkStatus,
  } = state.managerState.trademarkDashboardState;

  const { teamMember, teamMemberStatus } = state.profile;

  return deepFreeze({
    teamMember,
    teamMemberStatus,
    manageTrademarkType,
    manageTrademark,
    manageTrademarkStatus,
    lastAppliedFilters: manageTrademarkLastAppliedFilters,
    pageNumber: manageTrademarkNumber,
    deleteManageTrademarkStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTrademark);
