import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _ from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  Checkbox,
  message,
  notification,
  Typography,
  Tooltip,
} from "antd";
import { history } from "../../../../history";
import {
  getCustomList,
  deleteCustomList,
} from "../../../../redux/actions/managerActions/profile/actions";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";

import { selectFilterValue } from "../../../../functions/commonFunctions/generalFunctions";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
} from "../../../../constants/managerConstants/trademarkConstants";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import DownloadReportModal from "../../report/DownloadReportModal";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import CreateTask from "../../to-do-task/CreateTask";
import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { ITM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_ITM_NOTES,
  PATCH_ITM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import {
  changeStateValue_L0,
  changeStateValue_L1,
} from "../../../../functions/commonFunctions/generalFunctions";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";
import FilterSearchBasic from "./../../../commonComponents/FilterComponents/FilterSearchBasic";

const { Text } = Typography;
const { Search } = Input;

class InternationalTrademark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      visible: false,
      range1: null,
      range2: null,
      international_trademark_id: "",
      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      searchFilters: TM_DefaultSearchFilters,
      selectedFilters: this.props.lastAppliedFilters,
      pageNumber: this.props.pageNumber,
      addTaskVisible: false,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.selectFilterValue = selectFilterValue.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.internationalTrademark === null) {
        this.props.getCustomList({
          operation_type: "trademark",
          object_type: "international",
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleInternationalTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem("international_trademark_id", data.id);
      sessionStorage.setItem(
        "international_trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/docketed-trademark/international-trademark-profile/" +
          data.id,
        "_blank",
      );
      this.setState({
        international_trademark_id: data.id,
      });
    }
  }

  downloadReport() {
    if (this.state.add_trademark_id.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  async deleteMarks() {
    if (this.state.add_trademark_id.length > 0) {
      await this.props.deleteCustomList({
        operation_type: "trademark",
        object_type: "international",
        selected_marks: this.state.add_trademark_id,
      });
      notification[
        this.props.deleteInternationalTrademarkStatus === SUCCESS
          ? "success"
          : this.props.deleteInternationalTrademarkStatus === ERROR
          ? "error"
          : "smile"
      ]({
        message: "Deleted International Trademark",
        description:
          this.props.deleteInternationalTrademarkStatus === SUCCESS
            ? this.props.deleteInternationalTrademark.message
            : this.props.deleteInternationalTrademarkStatus === ERROR
            ? "Unfortunately, an error occured while International Trademark Deleting. Please, try again later."
            : "",
      });
      this.props.getCustomList({
        operation_type: "trademark",
        object_type: "international",
        filterFlag: this.props.lastAppliedFilters.filterFlag,
        filters: this.props.lastAppliedFilters,
        pageNumber: this.props.pageNumber,
      });
      this.setState({
        add_trademark_id: [],
        selectedRowKeys: [],
        selectAll: false,
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  applyFilter() {
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkOffice,
      trademarkCountry,
      trademarkProprietors,
      trademarkReferenceNo,
      date_of_application,
      valid_upto_date,
      containsTerm,
    } = this.state.selectedFilters;
    if (
      trademarkClass.length > 0 ||
      trademarkStatus.length > 0 ||
      trademarkType.length > 0 ||
      trademarkOffice.length > 0 ||
      trademarkCountry.length > 0 ||
      trademarkProprietors.length > 0 ||
      trademarkReferenceNo.length > 0 ||
      date_of_application.length > 0 ||
      valid_upto_date.length > 0 ||
      containsTerm.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });
      this.props.getCustomList({
        operation_type: "trademark",
        object_type: "international",
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  resetFilter() {
    this.setState({ range1: null, range2: null });
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkOffice,
      trademarkCountry,
      trademarkProprietors,
      trademarkReferenceNo,
      date_of_application,
      valid_upto_date,
      containsTerm,
    } = this.props.lastAppliedFilters;
    if (
      trademarkClass.length > 0 ||
      trademarkStatus.length > 0 ||
      trademarkType.length > 0 ||
      trademarkOffice.length > 0 ||
      trademarkCountry.length > 0 ||
      trademarkProprietors.length > 0 ||
      trademarkReferenceNo.length > 0 ||
      date_of_application.length > 0 ||
      valid_upto_date.length > 0 ||
      containsTerm.length > 0
    ) {
      this.setState({
        searchFilters: TM_DefaultSearchFilters,
        selectedFilters: {
          ...this.state.selectedFilters,
          ...TM_DefaultSelectedFilters,
          filterFlag: false,
        },
        pageNumber: 1,
      });
      this.props.getCustomList({
        operation_type: "trademark",
        object_type: "international",
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  handleNextpage = (pageNumber) => {
    this.props.getCustomList({
      operation_type: "trademark",
      object_type: "international",
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>International Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: ["INTERNATIONAL_TRADEMARK"],
        report_tag: {
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          journal_number: "",
          journal_date: "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
          opponent_name: "",
        },
        objectType: "custom_trademark",
        applicationNumber: data.application_number,
      },
    });

    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "INTERNATIONAL_TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { selectedFilters, searchFilters } = this.state;
    const totalTrademarkColumns = [
      {
        title: "TRADEMARK",
        className: "trademark-column",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleInternationalTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "STATUS",
        dataIndex: "status",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "CLASS",
        dataIndex: "associated_class",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "TYPE",
        dataIndex: "type",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "OFFICE",
        dataIndex: "application_office",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "FILING COUNTRY",
        dataIndex: "country",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.name.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "DATE OF APPLICATION",
        dataIndex: "application_date",
        className: "trademark-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "VALID UPTO",
        dataIndex: "valid_upto",
        className: "trademark-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "PROPRIETOR",
        dataIndex: "proprietor",
        className: "trademark-column",
        render: (proprietor) => {
          return (
            <div>
              {proprietor.map((proprietorData, proprietorKey) => (
                <p key={proprietorKey} className="text-camelCase">
                  {checkValue(proprietorData.name)
                    ? proprietorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "REFERENCE NUMBER",
        dataIndex: "reference_number",
        className: "trademark-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "ACTION",
        className: "trademark-column",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalTrademarkRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_trademark_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_trademark_id]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },
      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.internationalTrademark &&
          this.props.internationalTrademark.trademarks;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      // onSelect: (record, selected, selectedRows ) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: false,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let statusMenu = noFilterData;
    let typeMenu = noFilterData;
    let officeMenu = noFilterData;
    let countryMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let referenceNumbersMenu = noFilterData;

    if (this.props.internationalTrademarkStatus === SUCCESS) {
      const {
        classes,
        status,
        type,
        office,
        country,
        proprietors,
        reference_numbers,
      } = this.props.internationalTrademark.filters;

      if (classes !== null && classes.length > 0) {
        classesMenu = (
          <FilterSearchBasic
            data={classes}
            searchFilters={searchFilters}
            search_key={"classSearch"}
            filter_key={"trademarkClass"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"number"}
          />
        );
      }

      if (status !== null && status.length > 0) {
        statusMenu = (
          <FilterSearchBasic
            data={status}
            searchFilters={searchFilters}
            search_key={"statusSearch"}
            filter_key={"trademarkStatus"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (type !== null && type.length > 0) {
        typeMenu = (
          <FilterSearchBasic
            data={type}
            searchFilters={searchFilters}
            search_key={"typeSearch"}
            filter_key={"trademarkType"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (office !== null && office.length > 0) {
        officeMenu = (
          <FilterSearchBasic
            data={office}
            searchFilters={searchFilters}
            search_key={"officeSearch"}
            filter_key={"trademarkOffice"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (country !== null && country.length > 0) {
        countryMenu = (
          <FilterSearchBasic
            data={country}
            searchFilters={searchFilters}
            search_key={"countrySearch"}
            filter_key={"trademarkCountry"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        proprietorsMenu = (
          <FilterSearchBasic
            data={proprietors}
            searchFilters={searchFilters}
            search_key={"proprietorSearch"}
            filter_key={"trademarkProprietors"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }

      if (reference_numbers !== null && reference_numbers.length > 0) {
        referenceNumbersMenu = (
          <FilterSearchBasic
            data={reference_numbers}
            searchFilters={searchFilters}
            search_key={"referenceNoSearch"}
            filter_key={"trademarkReferenceNo"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }
    }

    return (
      <div>
        {/* Back */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={12}
            className="alignC"
            style={{ fontSize: "14px!important" }}
          >
            <Text strong style={{ margin: "0px", fontSize: "16px" }}>
              TOTAL INTERNATIONAL TRADEMARK :{" "}
              {checkValue(this.props.internationalTrademark)
                ? this.props.internationalTrademark.count
                : 0}{" "}
            </Text>
          </Col>
        </Row>
        {/* Back */}

        {/* International Trademarks filter */}
        <Row>
          <Col span={18} className="alignC m-top-10">
            <Menu
              selectedKeys={[
                this.state?.selectedFilters?.trademarkClass?.length > 0
                  ? "classesMenu"
                  : "",
                this.state?.selectedFilters?.trademarkStatus?.length > 0
                  ? "statusMenu"
                  : "",
                this.state?.selectedFilters?.trademarkType?.length > 0
                  ? "typeMenu"
                  : "",
                this.state?.selectedFilters?.trademarkOffice?.length > 0
                  ? "officeMenu"
                  : "",
                this.state?.selectedFilters?.trademarkCountry?.length > 0
                  ? "countryMenu"
                  : "",
                this.state?.selectedFilters?.trademarkProprietors?.length > 0
                  ? "proprietorsMenu"
                  : "",
                this.state?.selectedFilters?.trademarkReferenceNo?.length > 0
                  ? "referenceNumbersMenu"
                  : "",
                this.state?.selectedFilters?.date_of_application?.length > 0
                  ? "applicationDate"
                  : "",
                this.state?.selectedFilters?.valid_upto_date?.length > 0
                  ? "validUptoDate"
                  : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="classesMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => classesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "classesMenu")
                  }
                  open={this.state.open === "classesMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Class </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="statusMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => statusMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statusMenu")
                  }
                  open={this.state.open === "statusMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="typeMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => typeMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "typeMenu")
                  }
                  open={this.state.open === "typeMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Type </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="officeMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => officeMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "officeMenu")
                  }
                  open={this.state.open === "officeMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Office </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="countryMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => countryMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "countryMenu")
                  }
                  open={this.state.open === "countryMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Country </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="proprietorsMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => proprietorsMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "proprietorsMenu")
                  }
                  open={this.state.open === "proprietorsMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Proprietor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item
                key="referenceNumbersMenu"
                className="filter-list-item"
              >
                <Dropdown
                  dropdownRender={() => referenceNumbersMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "referenceNumbersMenu")
                  }
                  open={this.state.open === "referenceNumbersMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Reference No. </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="applicationDate" className="filter-list-item">
                <Dropdown
                  overlay={
                    <DateRangePicker
                      type={"Application Date"}
                      startValue={
                        this.state.selectedFilters.application_date_startValue
                      }
                      endValue={
                        this.state.selectedFilters.application_date_endValue
                      }
                      setDate={(val) => this.setDate1(val)}
                      range={this.state.range1}
                      handleDateString={(startValue, endValue, dateString) =>
                        this.setState({
                          selectedFilters: {
                            ...this.state.selectedFilters,
                            application_date_startValue: startValue,
                            application_date_endValue: endValue,
                            date_of_application:
                              startValue !== null || endValue !== null
                                ? dateString
                                : null,
                          },
                        })
                      }
                    />
                  }
                  overlayClassName="filter-menu"
                >
                  <div className="ant-dropdown-link">
                    <span>Application Date </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="validUptoDate" className="filter-list-item">
                <Dropdown
                  overlay={
                    <DateRangePicker
                      type={"Valid Upto Date"}
                      startValue={
                        this.state.selectedFilters.valid_upto_startValue
                      }
                      endValue={this.state.selectedFilters.valid_upto_endValue}
                      setDate={(val) => this.setDate2(val)}
                      range={this.state.range2}
                      handleDateString={(startValue, endValue, dateString) =>
                        this.setState({
                          selectedFilters: {
                            ...this.state.selectedFilters,
                            valid_upto_startValue: startValue,
                            valid_upto_endValue: endValue,
                            valid_upto_date:
                              startValue !== null || endValue !== null
                                ? dateString
                                : null,
                          },
                        })
                      }
                    />
                  }
                  overlayClassName="filter-menu"
                >
                  <div className="ant-dropdown-link">
                    <span>Valid Upto Date </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Col>
          <Col
            span={6}
            className="alignR m-top-10"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Text style={{ padding: "0px 5px", wordBreak: "keep-all" }}>
              {this.state.selectAll
                ? this.props.internationalTrademark &&
                  this.props.internationalTrademark.count
                : _.uniq(this.state.add_trademark_id).length}{" "}
              Mark(s)
            </Text>
            <Button
              type="primary"
              style={{ margin: "0px 5px" }}
              onClick={() => this.downloadReport()}
            >
              Report
            </Button>
            <Button
              danger
              style={{
                backgroundColor: "#FDE5E7",
                color: "red",
                display: "flex",
                alignItems: "center",
              }}
              loading={
                this.props.deleteInternationalTrademarkStatus === LOADING
              }
              onClick={() => this.deleteMarks()}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col
            span={9}
            // className="alignC"
            style={{
              borderBottom: "1px solid #E9E9E9",
            }}
          >
            <Menu
              selectedKeys={[]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item
                key="contentTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.selectFilterValue(
                      "selectedFilters",
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={this.state?.selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                  onKeyDown={(event) => {
                    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                      event.stopPropagation(); // Prevent arrow keys from propagating
                    }
                  }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* International Trademarks filter */}

        {/* International Trademarks table */}
        <Row>
          <Col span={24}>
            <div className="m-top-20 international-trademark">
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={totalTrademarkRowSelection}
                columns={totalTrademarkColumns}
                dataSource={
                  this.props.internationalTrademark &&
                  this.props.internationalTrademark.trademarks
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: this.props.pageNumber,
                  total:
                    this.props.internationalTrademark &&
                    this.props.internationalTrademark.count,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(100vh - 300px)" }}
                loading={
                  this.props.internationalTrademarkStatus === LOADING
                    ? true
                    : false
                }
              />
            </div>
          </Col>
        </Row>
        {/* International Trademarks table */}

        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"trademark"}
            customTrademark={"international_trademark"}
            internationalTrademark={"international_trademark"}
            add_trademark_id={this.state.add_trademark_id}
            selectAll={this.state.selectAll}
            filters={this.props.lastAppliedFilters}
          />
        )}

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_ITM_NOTES, PATCH_ITM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={ITM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          sub_section="DASHBOARD"
        />
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  getCustomList,
  deleteCustomList,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const {
    internationalTrademark,
    internationalTrademarkStatus,
    internationalLastAppliedFilters,
    internationalPageNumber,
    deleteInternationalTrademark,
    deleteInternationalTrademarkStatus,
  } = state.managerState.profileState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    internationalTrademark,
    internationalTrademarkStatus,
    lastAppliedFilters: internationalLastAppliedFilters,
    pageNumber: internationalPageNumber,
    deleteInternationalTrademark,
    deleteInternationalTrademarkStatus,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternationalTrademark);
